import { CASH_FLOW_TYPE, VALUE_FORMAT, getPeriod } from './api';
import { createDatestamp } from './date';
import {
    isValidObject,
    validArray,
    validNumber,
    validString
} from './valid';

const datestamp = createDatestamp();

const recentMonth = getPeriod('RECENT_MONTH');
const threeMonth = getPeriod('THREE_MONTHS');
const oneYear = getPeriod('ONE_YEAR');

/**
 * @property {object} CASHFLOW_REQUEST_CHUNKS A collection of cashflow chunks to request by user type
 */
export const CASHFLOW_REQUEST_CHUNKS = {
    firstTimeUser: [{
        alias: 'projected_income',
        period: 0,
        cashFlowType: 'PROJECTED_INCOME',
        resultType: 'MONTHLY'
    }, {
        alias: 'expense',
        period: 0,
        cashFlowType: 'BUDGET',
        resultType: 'MONTHLY'
    }, {
        alias: 'budget',
        period: 0,
        cashFlowType: 'BUDGET',
        resultType: 'MONTHLY_UNDER_CATEGORY'
    }, {
        alias: 'saving',
        period: 0,
        cashFlowType: 'SAVING_TARGET',
        resultType: 'MONTHLY'
    }],
    repeatUser: [{
        alias: 'saving_target',
        period: 0,
        cashFlowType: 'SAVING_TARGET',
        resultType: 'MONTHLY'
    }, {
        alias: 'projected_income',
        period: 0,
        cashFlowType: 'PROJECTED_INCOME',
        resultType: 'MONTHLY'
    }, {
        alias: 'budget',
        period: 0,
        cashFlowType: 'BUDGET',
        resultType: 'MONTHLY_UNDER_CATEGORY'
    }, {
        alias: 'expense',
        period: 0,
        cashFlowType: 'EXPENSE',
        resultType: 'MONTHLY'
    }]
};

/**
 * @property {object} EDIT_BUDGET_REQUEST A collection of edit budget request chunks
 */
export const EDIT_BUDGET_REQUEST = [{
    period: recentMonth,
    cashFlowType: 'PROJECTED_INCOME',
    resultType: 'MONTHLY'
}, {
    period: recentMonth,
    cashFlowType: 'BUDGET',
    resultType: 'MONTHLY'
}, {
    period: recentMonth,
    cashFlowType: 'BUDGET',
    resultType: 'MONTHLY_UNDER_CATEGORY'
}, {
    period: recentMonth,
    cashFlowType: 'SAVING_TARGET',
    resultType: 'MONTHLY'
}];

/**
 * @property {object} OVERVIEW_ALL A collection of chunks to request for the cashflow overall: all view
 */
export const OVERVIEW_ALL = [{
    cashflowType: CASH_FLOW_TYPE.INCOME,
    format: VALUE_FORMAT.MONTHLY,
    period: recentMonth
}, {
    cashflowType: CASH_FLOW_TYPE.EXPENSE,
    format: VALUE_FORMAT.MONTHLY,
    period: recentMonth
}, {
    cashflowType: CASH_FLOW_TYPE.BUDGET,
    format: VALUE_FORMAT.MONTHLY,
    period: recentMonth
}, {
    cashflowType: CASH_FLOW_TYPE.SAVING_TARGET,
    format: VALUE_FORMAT.MONTHLY,
    period: recentMonth
}, {
    cashflowType: CASH_FLOW_TYPE.SAVING,
    format: VALUE_FORMAT.SUM,
    period: oneYear
}];

/**
 * @property {object} OVERVIEW_PRE_SAVING A collection of chunks to request for the cashflow overall: pre-saving view
 */
export const OVERVIEW_PRE_SAVING = [{
    cashflowType: CASH_FLOW_TYPE.INCOME,
    format: VALUE_FORMAT.AVERAGE,
    period: threeMonth
}, {
    cashflowType: CASH_FLOW_TYPE.EXPENSE,
    format: VALUE_FORMAT.AVERAGE,
    period: threeMonth
}, {
    cashflowType: CASH_FLOW_TYPE.EXPENSE,
    format: VALUE_FORMAT.AVERAGE_UNDER_CATEGORY,
    period: threeMonth
}, {
    cashflowType: CASH_FLOW_TYPE.SAVING,
    format: VALUE_FORMAT.AVERAGE,
    period: threeMonth
}];

/**
 * @function getCashflowRequests
 * @description Generates the request chunks for cashflows with dynamically-generated datestamps
 * @param {object} duration The month and year to request
 * @returns {array} The generated chunks
 */
export function getCashflowRequests(duration) {
    const { month, year } = duration;
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const isRecentSelection = month === currentMonth && year === currentYear;
    const selectedPeriod = isRecentSelection
        ? 0
        : 12;

    return {
        cashflow_requests: [{
            cashFlowType: 'EXPENSE',
            resultType: 'MONTHLY_UNDER_CATEGORY',
            period: selectedPeriod
        }, {
            cashFlowType: 'EXPENSE',
            resultType: 'MONTHLY',
            period: selectedPeriod
        }, {
            cashFlowType: 'BUDGET',
            resultType: 'MONTHLY',
            period: selectedPeriod
        }, {
            cashFlowType: 'BUDGET',
            resultType: 'MONTHLY_UNDER_CATEGORY',
            period: selectedPeriod
        }, {
            cashFlowType: 'INCOME',
            resultType: 'SUM',
            period: selectedPeriod
        }, {
            cashFlowType: 'PROJECTED_INCOME',
            resultType: 'MONTHLY',
            period: selectedPeriod
        }, {
            cashFlowType: 'SAVING_TARGET',
            resultType: 'MONTHLY',
            period: selectedPeriod
        }]
    };
}

/**
 * @function getLatestValue
 * @description Retrieves the latest item in a collection of datestamp-value pairs
 * @param {object} datum The collection to parse
 * @returns {number} The value of the latest item or `0`
 */
export function getLatestValue(datum) {
    if (!isValidObject(datum)) {
        return 0;
    }

    const keys = Object.keys(datum);
    const keyCount = keys.length || 0;

    if (keyCount === 0) {
        return 0;
    }

    const latestKey = keys.sort()[keyCount - 1];

    return validNumber(datum[latestKey]);
}

/**
 * @function getCurrentMonthValue
 * @description Retrieves the value for the latest month in a datapoint
 * @param {object} datum The collection to parse
 * @returns {number} The value of the latest month or `0`
 */
export function getCurrentMonthValue(datum) {
    let returnValue = 0;

    if (isValidObject(datum)) {
        // Favour the current month, fall back to the latest
        returnValue = validNumber(datum[datestamp]) || getLatestValue(datum);
    }

    return returnValue;
}

/**
 * @function generateRangeData
 * @description Generates a collection of range data from an array of overview datapoints
 * @param {array} data The array to parse
 * @returns {object} The collection of key:value overview datapoints
 */
export function generateRangeData(data = []) {
    const response = {};

    validArray(data).forEach(datum => {
        const {
            alias,
            cashFlowType,
            resultType,
            value
        } = datum;

        let key = validString(alias, cashFlowType).toLowerCase();

        if (key === 'expense' && resultType === 'AVERAGE_UNDER_CATEGORY') {
            key = 'averageExpense';
        }

        response[key] = getCurrentMonthValue(value);
    });

    return response;
}

export default null;
