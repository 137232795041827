import { getMonth, subMonths, isToday } from 'cwp-dev-shared/helpers/date';
import { formatDate as format } from 'cwp-dev-shared/helpers/formatter';
import { validNumber } from './valid';

/**
 * @function generateMonthNames
 * @description Generates a collection of month names for a chart
 * @param {number} months The number of months to generate
 * @param {date} startFrom The starting date
 * @param {object} options A collection of options for formatting
 * @returns {object} The formatted dates
 */
export function generateMonthNames(months, startFrom, {
    displayFormat = 'MMM',
    altDisplayFormat = 'YYYY',
    monthLabelFormat = 'MM',
    formatDate = format
} = {}) {
    // @todo This method wants to return an object, not an array
    if (validNumber(months) < 1) {
        return [];
    }

    const now = startFrom || new Date();
    const addRecentMonth = isToday(now) ? 0 : 1;
    let itr = 0;
    const names = [];
    const labels = [];
    const graphLabel = [];

    while (itr <= (months - addRecentMonth)) {
        const monthDate = subMonths(now, months - itr);
        const monthName = formatDate(monthDate, false, '', {}, displayFormat);
        const labelYear = formatDate(monthDate, false, '', {}, altDisplayFormat);
        const labelMonth = formatDate(monthDate, false, '', {}, monthLabelFormat);

        names.push(monthName);
        graphLabel.push(`${labelYear}-${labelMonth}`);

        let labelname = null;

        if (itr === 0 || getMonth(monthDate) === 0) {
            labelname = labelYear;
        } else if ((months - itr) === 0) {
            labelname = 'In progress';
        }

        labels.push(labelname);
        itr += 1;
    }

    return {
        graphLabel,
        labels,
        names
    };
}

export default null;
