import { getRequestService } from '../helpers/requests';
import { isValidNumber, isValidObject, isValidString } from '../helpers/valid';
import {
    DATA_SOURCE_IDS,
    STATISTICS_DATA_SOURCE_IDS,
    USER_STATES
} from './constants';

/**
 * @function dataSourceExists
 * @description Determines if a data source exists before we use it
 * @param {string} dataSourceId The name of the data source to check
 * @returns {Boolean} Was the data source registered?
 */
export function dataSourceExists(dataSourceId) {
    if (!isValidString(dataSourceId)) {
        return false;
    }

    try {
        return isValidObject(window.mwc.dataManager.dataSources[dataSourceId]);
    } catch (e) {
        return false;
    }
}

/**
 * @function refreshStatisticsSources
 * @description Refreshes statistics data sources
 * @returns {void}
 */
export async function refreshStatisticsSources() {
    STATISTICS_DATA_SOURCE_IDS.forEach(async dataSourceId => {
        const dataSourceIsRegistered = dataSourceExists(dataSourceId);

        if (dataSourceIsRegistered) {
            const { parameters } = window.mwc.dataManager.dataSources[dataSourceId];

            await window.PFM_ORCHESTRATION.trigger('data-subscriber', {
                dataSourceId,
                parameters,
                sourceTask: 'update'
            });
        }
    });
}

/**
 * @function resetAggregationStatus
 * @description Resets a user's aggregation status once all non-investment accounts are unlinked
 * @returns {void}
 */
export function resetAggregationStatus() {
    window.PFM_ORCHESTRATION.changeUserStatus(USER_STATES.FIRST_TIME_PRE_AGGREGATION);
}

/**
 * @function setFirstTimeBudgetStatus
 * @description Sets a user's budget status once all non-investment accounts are linked
 * @returns {void}
 */
export function setFirstTimeBudgetStatus() {
    window.PFM_ORCHESTRATION.changeUserStatus(USER_STATES.FIRST_TIME_BUDGET);
}

/**
 * @function triggerBaaAccountUnlink
 * @description Notifies subscribers of an unlinked BAA account
 * @param {object} account The unlinked account
 * @returns {void}
 */
export async function triggerBaaAccountUnlink(account) {
    await window.PFM_ORCHESTRATION.trigger('data-subscriber', {
        dataSourceId: DATA_SOURCE_IDS.BAA_ACCOUNT_UNLINKED,
        parameters: account,
        sourceTask: 'update'
    });
}

/**
 * @function triggerBaaUpdate
 * @description Updates the BAA data source
 * @returns {void}
 */
export async function triggerBaaUpdate() {
    await window.PFM_ORCHESTRATION.trigger('data-subscriber', {
        dataSourceId: DATA_SOURCE_IDS.BAA_ACCOUNTS,
        parameters: null,
        sourceTask: 'update'
    });
}

/**
 * @function deleteBaaCredential
 * @description Deletes a BAA credential
 * @param {string} credentialId The BAA ID for the credential being deleted
 * @param {object} sourceSettings The settings object for the data source
 * @param {string} tokens The additional auth tokens to be passed to BAA
 * @returns {void}
 */
export async function deleteBaaCredential(credentialId, sourceSettings, tokens = '', triggerUpdate = true) {
    const { url: urlRoot } = sourceSettings;
    const url = `${urlRoot}/${credentialId}${tokens}`;
    const { service } = getRequestService();

    await service({
        ...sourceSettings,
        url
    }).then(() => {
        // Do nothing
    }).catch(() => {
        // Do nothing
    }).finally(() => {
        if (triggerUpdate === true) {
            triggerBaaUpdate();
        }
    });
}

/**
 * @function triggerNetWorthAccountDelete
 * @description Notifies subscribers of a deleted Office/PFM account
 * @param {object} account The unlinked account
 * @returns {void}
 */
export async function triggerNetWorthAccountDelete(account) {
    await window.PFM_ORCHESTRATION.trigger('data-subscriber', {
        dataSourceId: DATA_SOURCE_IDS.NET_WORTH_ACCOUNT_DELETED,
        parameters: account,
        sourceTask: 'update'
    });
}

/**
 * @function triggerNetWorthUpdate
 * @description Updates the net worth data source
 * @returns {void}
 */
export async function triggerNetWorthUpdate() {
    await window.PFM_ORCHESTRATION.trigger('data-subscriber', {
        dataSourceId: DATA_SOURCE_IDS.NET_WORTH_ACCOUNTS,
        parameters: null,
        sourceTask: 'update'
    });
}

/**
 * @function deleteOfficeAccount
 * @description Deletes an Office account
 * @param {string} financialItemId The financialItemId for the account being deleted from Office
 * @param {object} sourceSettings The settings object for the data source
 * @returns {void}
 */
export async function deleteOfficeAccount({
    financialItemId,
    financialItemType,
    sourceSettings,
    triggerUpdate = false
}) {
    const { url: urlRoot } = sourceSettings;
    const url = `${urlRoot}/?financialItemId=${financialItemId}&financialItemType=${financialItemType}`;
    const { service } = getRequestService();

    await service({
        ...sourceSettings,
        url
    }).then(() => {
        // Do nothing
    }).catch(() => {
        // Do nothing
    }).finally(() => {
        if (triggerUpdate === true) {
            triggerNetWorthUpdate();
        }
    });
}

/**
 * @function deletePfmAccount
 * @description Deletes a PFM account
 * @param {string} importExternalId The importExternalId for the account being deleted from PFM
 * @param {object} sourceSettings The settings object for the data source
 * @returns {void}
 */
export async function deletePfmAccount(importExternalId, sourceSettings, triggerUpdate = false) {
    const { url: urlRoot } = sourceSettings;
    const url = `${urlRoot}/${importExternalId}`;
    const { service } = getRequestService();

    await service({
        ...sourceSettings,
        url
    }).then(() => {
        // Do nothing
    }).catch(() => {
        // Do nothing
    }).finally(() => {
        if (triggerUpdate === true) {
            triggerNetWorthUpdate();
        }
    });
}

/**
 * @function saveBudgetIncomeSaving
 * @description Saves budget, income and savings target updates
 * @param {object} budgetData The updated budget, income and/or savings target data
 * @param {object} sources A collection of data source settings
 * @returns {void}
 */
export async function saveBudgetIncomeSaving(budgetData, sources, valuePeriod = 0) {
    const { INCOME, BUDGET, SAVING } = budgetData;
    const { service } = getRequestService();
    const sourceObject = { ...sources };

    const generatePut = (sourceSettings, data) => service({
        ...sourceSettings,
        body: JSON.stringify(data),
        crossDomain: true,
        returnData: true
    });

    const promiseArray = [];

    if (isValidNumber(INCOME)) {
        promiseArray.push(generatePut(sources.setIncome, {
            'projected-income': INCOME
        }));
    }

    if (isValidObject(BUDGET)) {
        sourceObject.setBudget.url = `${sourceObject.setBudget.url}?period=${valuePeriod}`;
        promiseArray.push(generatePut(
            sourceObject.setBudget,
            BUDGET
        ));
    }

    if (isValidNumber(SAVING)) {
        sourceObject.setSavingsTarget.url = `${sourceObject.setSavingsTarget.url}?period=${valuePeriod}`;
        promiseArray.push(generatePut(sourceObject.setSavingsTarget, {
            'saving-target': SAVING
        }));
    }

    if (promiseArray.length) {
        await Promise.all(promiseArray);
        await window.PFM_ORCHESTRATION.refreshData();
        return true;
    }

    return false;
}

/**
 * @function updateTransactionsCategory
 * @description Updates the category of one more more transactions
 * @param {string} _route The route to the component for transaction updates
 * @param {string} cashFlowType Required to update transactions for a given type
 * @param {object} sourceSettings The settings object for the data source
 * @param {string} spendingCategory The new category for the transactions
 * @param {number} spendingCategoryId The new category ID for the transactions
 * @param {array} transactions The transactions being updated
 * @returns {void}
 */
export async function updateTransactionsCategory({
    sourceSettings,
    spendingCategory,
    spendingCategoryId,
    transactions
}) {
    const { service } = getRequestService();
    // Exclude transactions that don't need to change
    const payload = transactions.filter(item => item.spendingCategory !== spendingCategory)
        .map(transaction => ({
            ...transaction,
            spendingCategory,
            spendingCategoryId
        }));

    await service({
        ...sourceSettings,
        body: JSON.stringify(payload)
    }).then(() => {
        // Do nothing
    }).catch(() => {
        // Do nothing
    }).finally(() => {
        refreshStatisticsSources();
    });
}

/**
 * @function updateTransactionsInclusion
 * @description Updates the include/exclude of one more more transactions
 * @param {string} _route The route to the component for transaction updates
 * @param {string} cashFlowType Required to update transactions for a given type
 * @param {object} sourceSettings The settings object for the data source
 * @param {boolean} excludedFromCalculation Should the transaction be excluded?
 * @param {array} transactions The transactions being updated
 * @returns {void}
 */
export async function updateTransactionsInclusion({
    sourceSettings,
    excludedFromCalculation,
    transactions
}) {
    const { service } = getRequestService();
    // Exclude transactions that don't need to change
    const payload = transactions.filter(item => item.excludedFromCalculation !== excludedFromCalculation)
        .map(transaction => ({
            ...transaction,
            excludedFromCalculation
        }));

    await service({
        ...sourceSettings,
        body: JSON.stringify(payload)
    }).then(() => {
        // Do nothing
    }).catch(() => {
        // Do nothing
    }).finally(() => {
        refreshStatisticsSources();
    });
}

/**
 * @function unlinkBaaAccount
 * @description Deletes a BAA credential
 * @param {string} accountId The BAA ID for the account being unlinked
 * @param {object} sourceSettings The settings object for the data source
 * @param {string} tokens The additional auth tokens to be passed to BAA
 * @returns {void}
 */
export async function unlinkBaaAccount(accountId, sourceSettings, tokens = '', triggerUpdate = true) {
    const { url: urlRoot } = sourceSettings;
    const url = `${urlRoot}/${accountId}${tokens}`;
    const { service } = getRequestService();

    await service({
        ...sourceSettings,
        url
    }).then(() => {
        // Do nothing
    }).catch(() => {
        // Do nothing
    }).finally(() => {
        if (triggerUpdate === true) {
            triggerBaaUpdate();
        }
    });
}

export default null;
