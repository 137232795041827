import { findTargetElement, isValidElement, setElementLoading } from '../helpers/elements';
import { getRequestService } from '../helpers/requests';
import { isValidString } from '../helpers/valid';

/**
 * @function getRequestFactory
 * @description A factory to create a generic request-based data source
 * @param {object} sourceSettings The API settings for the data source
 * @returns {function} The data source
 */
export function getRequestFactory(sourceSettings) {
    const { service } = getRequestService();

    return function getRequestSource() {
        return service({
            ...sourceSettings
        });
    };
}

/**
 * @function getStatisticsFactory
 * @description A factory to create a request to the statistics data source
 * @param {object} sourceSettings The API settings for the data source
 * @returns {function} The data source
 */
export function getStatisticsFactory(sourceSettings) {
    return function getStatistics(parameters) {
        const { chunks } = parameters || {};
        const { service } = getRequestService();

        if (!chunks || !chunks.length) {
            return Promise.resolve([]);
        }

        const data = {
            cashflow_requests: chunks
        };

        return service({
            ...sourceSettings,
            body: JSON.stringify(data)
        });
    };
}

/**
 * @function getTransactionFactory
 * @description A factory to create a request to the transactions data source
 * @param {string} mwcId The MWC ID of the calling element
 * @param {string} target The target element
 * @param {object} sourceSettings The API settings for the data source
 * @returns {function} The data source
 */
export function getTransactionFactory(mwcId, target, sourceSettings) {
    return function getTransactions(parameters) {
        const { cashFlowType } = parameters || {};
        const { service } = getRequestService();

        if (!isValidString(cashFlowType)) {
            setElementLoading({
                mwcId,
                target,
                loading: false
            });

            return Promise.resolve([]);
        }

        setElementLoading({
            mwcId,
            target,
            loading: true
        });

        return service({
            ...sourceSettings
        }).then(res => {
            const { data, date } = res;
            const el = findTargetElement(mwcId, target);

            if (isValidElement(el)) {
                el.defaultStartEndDate = JSON.stringify(date);
            }

            return data;
        });
    };
}

export default null;
