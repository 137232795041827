import {
    ACCEPT,
    CONTENT_TYPE,
    RESPONSE_TYPE
} from './constants';

/**
 * @function isDataAccessAvailable
 * @description Verifies if the MWC Data Access framework is available
 * @returns {boolean} Is the framework available?
 */
export function isDataAccessAvailable() {
    return !!(window
        && window.mwc
        && window.mwc.dataAccess
        && typeof window.mwc.dataAccess.fetch === 'function');
}

/**
 * @function isDataManagerAvailable
 * @description Verifies if the MWC Data Manager framework is available
 * @returns {boolean} Is the framework available?
 */
export function isDataManagerAvailable() {
    return window
        && window.mwc
        && window.mwc.dataManager;
}

/**
 * @function isLoggerAvailable
 * @description Verifies if the MWC Logger framework is available
 * @returns {boolean} Is the framework available?
 */
export function isLoggerAvailable() {
    return window
        && window.mwc
        && window.mwc.logger;
}

/**
 * @function noop
 * @description A fallback method when a framework method is unavailable
 * @returns {void}
 */
function noop() {
    // Do nothing
}

/**
 * @property {object} DATA_ACCESS The MWC Data Access framework or a fallback
 * @const
 */
export const DATA_ACCESS = isDataAccessAvailable()
    ? window.mwc.dataAccess
    : {
        fetch: noop
    };

/**
 * @property {object} DATA_MANAGER The MWC Data Manager framework or a fallback
 * @const
 */
export const DATA_MANAGER = isDataManagerAvailable()
    ? window.mwc.dataManager
    : {};

/**
 * @property {object} MWC_LOGGER The MWC Logger framework or a fallback
 * @const
 */
export const MWC_LOGGER = isLoggerAvailable()
    ? window.mwc.logger
    : console;

/**
 * @function registerStore
 * @description Registers a store with the MWC Data Manager
 * @param {string} namespace The namespace to store the data in
 * @param {string} id The identifier for the stored item
 * @param {*} value The initial value to store
 * @returns {void}
 */
export function registerStore(namespace, id, value) {
    DATA_MANAGER.registerDataSource({
        dataSourceId: `${namespace}.sharedStore.${id}`,
        parameters: value,
        source: val => Promise.resolve(val)
    });
}

/**
 * @function subscribeToStore
 * @description Subscribes to an MWC Data Manager store
 * @param {string} namespace The namespace the data is stored in
 * @param {string} id The identifier for the stored item
 * @param {object} options Additional subscription options
 * @returns {void}
 */
export function subscribeToStore(namespace, id, options) {
    const settings = {
        ...options,
        dataSourceId: `${namespace}.sharedStore.${id}`
    };

    DATA_MANAGER.subscribe(settings);
}

/**
 * @function updateStore
 * @description Updates a stored value with the MWC Data Manager
 * @param {string} namespace The namespace to store the data in
 * @param {string} id The identifier for the stored item
 * @param {*} value The value to store
 * @returns {void}
 */
export function updateStore(namespace, id, value) {
    DATA_MANAGER.updateDataSource({
        dataSourceId: `${namespace}.sharedStore.${id}`,
        parameters: value
    });
}

/**
 * @function registerSource
 * @description Registers a data source with MWC Data Manager
 * @param {object} options The options for the data source
 * @returns {boolean} Was the registration successful?
 */
export function registerSource(options) {
    const {
        dataSourceId,
        fetchMechanism,
        metadata = {},
        parameters,
        useWorkflow
    } = options;

    return window.PFM_ORCHESTRATION.trigger('data-subscriber', {
        dataSourceId,
        fetchMechanism,
        parameters,
        metadata,
        useWorkflow,
        sourceTask: 'register'
    });
}

/**
 * @function subscribeToSource
 * @description Subscribes to an MWC Data Manager data source
 * @param {object} options The options for the data source
 * @returns {boolean} Was the subscription successful?
 */
export function subscribeToSource(options) {
    const {
        dataSourceId,
        element,
        errorFunction,
        modelName,
        mwcId,
        successFunction,
        transformations = []
    } = options;

    return window.PFM_ORCHESTRATION.trigger('data-subscriber', {
        element,
        mwcId,
        dataHooks: [{
            dataSourceId,
            errorFunction,
            modelName,
            successFunction,
            transformations
        }],
        sourceTask: 'subscribe'
    });
}

/**
 * @function request
 * @description Creates a pre-configured MWC Data Access fetch request
 * @param {object} options The options for the fetch request
 * @returns {Promise} The fetch request
 */
export function request(options) {
    const defaults = {
        headers: {
            Accept: ACCEPT.JSON,
            'Content-Type': CONTENT_TYPE.JSON
        },
        maxAttempts: 1,
        maxSleep: 800,
        minSleep: 400,
        mode: 'cors',
        responseType: RESPONSE_TYPE.JSON,
        source: 'uim'
    };

    const merged = { ...defaults, ...options };

    return DATA_ACCESS.fetch(merged)
        .then(response => {
            if (!response) {
                return merged.responseType === RESPONSE_TYPE.TEXT
                    ? ''
                    : null;
            }

            if (merged.responseType === RESPONSE_TYPE.TEXT && response.text) {
                return response.text();
            }

            if (merged.responseType === RESPONSE_TYPE.JSON && response.json) {
                return response.json();
            }

            return response;
        })
        .catch(e => {
            MWC_LOGGER.warn('pfmOrchestration error', e);
        });
}

export default null;
