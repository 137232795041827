import Vue from 'vue';
import { resolveBits } from './data';
import { isValidString, validString } from './valid';

/**
 * @function createEventHub
 * @description Creates an event hub for a specified MWC ID
 * @param {string} mwcId The MWC ID of the calling component
 * @returns {object} The new event hub
 */
export function createEventHub(mwcId) {
    const eventHub = new Vue({
        data: {
            mwcId: validString(mwcId)
        }
    });

    if (isValidString(mwcId)) {
        window.pfm = window.pfm || {};
        window.pfm[mwcId] = window.pfm[mwcId] || {};
        window.pfm[mwcId].eventHub = window.pfm[mwcId].eventHub || eventHub;

        return window.pfm[mwcId].eventHub;
    }

    return eventHub;
}

/**
 * @function getEventHub
 * @description Locates the event hub for a specified MWC ID
 * @param {string} mwcId The MWC ID of the calling component
 * @returns {object} The event hub or `null`
 */
export function getEventHub(mwcId) {
    if (!isValidString(mwcId)) {
        return null;
    }

    const bits = `pfm.${mwcId}.eventHub`;

    return resolveBits({
        bits
    });
}

export default null;
