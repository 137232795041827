import { CASH_FLOW_TYPE, VALUE_FORMAT, getPeriod } from './api';
import { validNumber } from './valid';

const recentMonth = getPeriod('RECENT_MONTH');
const oneYear = getPeriod('ONE_YEAR');

/**
 * @property {object} SAVING_ACCUMULATION_REQUEST A collection of saving accumulation chunks to request
 */
export const SAVING_ACCUMULATION_REQUEST = [{
    cashflowType: CASH_FLOW_TYPE.BUDGET,
    format: VALUE_FORMAT.MONTHLY,
    period: recentMonth
}, {
    cashflowType: CASH_FLOW_TYPE.SAVING_TARGET,
    format: VALUE_FORMAT.MONTHLY,
    period: recentMonth
}, {
    cashflowType: CASH_FLOW_TYPE.SAVING,
    format: VALUE_FORMAT.SUM,
    period: oneYear
}, {
    cashflowType: CASH_FLOW_TYPE.SAVING,
    format: VALUE_FORMAT.MONTHLY,
    period: oneYear
}];

/**
 * @property {object} SAVINGS_TIERS A collection of recommended savings targets based on income tiers
 */
export const SAVINGS_TIERS = {
    833: 8,
    4167: 10,
    8333: 12,
    12500: 15,
    16667: 18,
    833333: 20
};

/**
 * @function getTieredSavingsTarget
 * @description Determines the recommended savings target based on a given income
 * @param {number} income The income amount to compare
 * @returns {number} The savings target as a whole number percentage of income
 */
export function getTieredSavingsTarget(income) {
    const incomeLevels = Object.keys(SAVINGS_TIERS);
    const tierValue = incomeLevels.find(key => (key >= validNumber(income)));

    return tierValue
        ? SAVINGS_TIERS[tierValue]
        : SAVINGS_TIERS[incomeLevels.pop()];
}

export default null;
