import {
    CASHFLOW_REQUEST_CHUNKS,
    EDIT_BUDGET_REQUEST,
    getCashflowRequests
} from 'pfm-shared/helpers/cashflow';
import {
    findTargetElement,
    setElementLoading
} from 'pfm-shared/helpers/elements';
import {
    registerSource,
    registerStore,
    subscribeToSource,
    subscribeToStore,
    updateStore
} from 'pfm-shared/sources/helpers';
import { SAVING_ACCUMULATION_REQUEST } from 'pfm-shared/helpers/savings';
import { prepareTrendAnalysisChunks } from 'pfm-shared/helpers/trend-analysis';
import {
    isFalsy,
    isTruthy,
    isValidArray,
    isValidNumber,
    isValidObject,
    isValidString,
    validNumber,
    validString
} from 'pfm-shared/helpers/valid';
import { DATA_SOURCE_IDS, USER_STATES } from 'pfm-shared/sources/constants';
// eslint-disable-next-line no-unused-vars
import { getRequestFactory, getTransactionFactory } from 'pfm-shared/sources/factories';
import {
    registerAggregation,
    registerBudgetState,
    registerBudgetStatus,
    registerCategories,
    registerNetWorth,
    registerStatistics,
    registerStatisticsStartMonth,
    registerUserConfigs
} from 'pfm-shared/sources/registrations';
import {
    subscribeToAggregation,
    subscribeToBudgetState,
    subscribeToBudgetStatus,
    subscribeToCategories,
    subscribeToNetWorth,
    subscribeToStatistics,
    subscribeToStatisticsStartMonth,
    subscribeToUserConfigs
} from 'pfm-shared/sources/subscribers';
import {
    refreshStatisticsSources,
    saveBudgetIncomeSaving,
    setFirstTimeBudgetStatus,
    updateTransactionsCategory,
    updateTransactionsInclusion
} from 'pfm-shared/sources/tasks';

const NAMESPACE = {
    ROOT: 'pfmLayoutCashflowDetails',
    TREND_ANALYSIS_ALL: 'ds-trend-analysis-statistics-all',
    TREND_ANALYSIS_SAVINGS: 'ds-trend-analysis-statistics-savings',
    TREND_ANALYSIS_INCOME: 'ds-trend-analysis-statistics-income',
    TREND_ANALYSIS_EXPENSES: 'ds-trend-analysis-statistics-expenses',
    INCOME_TRANSACTION_TABLE: 'pfmLayoutCashflowDetails.pfmCashflowTransactionTableIncome',
    EXPENSE_TRANSACTION_TABLE: 'pfmLayoutCashflowDetails.pfmCashflowTransactionTableExpense',
    MONTHLY_BUDGET: 'pfmLayoutCashflowDetails.pfmMonthlyBudget'
};

const TREND_MODES = {
    [NAMESPACE.TREND_ANALYSIS_ALL]: 'all',
    [NAMESPACE.TREND_ANALYSIS_SAVINGS]: 'savings',
    [NAMESPACE.TREND_ANALYSIS_INCOME]: 'income',
    [NAMESPACE.TREND_ANALYSIS_EXPENSES]: 'expenses_and_budget'
};
const store = {};

function analyzeSpending() {
    setFirstTimeBudgetStatus();
}

function noop() {
    // Do nothing
}

async function onChangeTransactionCategory(action, dataSources) {
    // eslint-disable-next-line no-unused-vars
    const { _sharedConfig, ...apiSettings } = dataSources;
    const { detail } = action;
    const {
        spendingCategory,
        spendingCategoryId,
        selectedTransactions
    } = detail[0] || {};

    if (!isValidArray(selectedTransactions, 1)
        || !isValidString(spendingCategory)
        || validNumber(spendingCategoryId, -1) < 1) {
        return;
    }

    await updateTransactionsCategory({
        spendingCategory,
        spendingCategoryId,
        sourceSettings: apiSettings.putTransactions,
        transactions: selectedTransactions
    });
}

async function onChangeTransactionInclusion(action, dataSources) {
    // eslint-disable-next-line no-unused-vars
    const { _sharedConfig, ...apiSettings } = dataSources;
    const { detail } = action;
    const {
        excludedFromCalculation,
        selectedTransactions
    } = detail[0] || {};

    if (!isValidArray(selectedTransactions, 1)
        || (!isFalsy(excludedFromCalculation) && !isTruthy(excludedFromCalculation))) {
        return;
    }

    await updateTransactionsInclusion({
        excludedFromCalculation,
        sourceSettings: apiSettings.putTransactions,
        transactions: selectedTransactions
    });
}

async function prepareTrendAnalysis(action, dataSources) {
    const { _sharedConfig, _route, ...apiSettings } = dataSources;
    const { detail, target } = action;
    const {
        id: mwcId,
        startFrom,
        duration,
        timeline,
        mode
    } = detail[0] || {};

    if (_sharedConfig.trendTimeline) {
        // if it already exists it won't be overwritten
        registerStore(NAMESPACE.ROOT, 'trendTimeline', dataSources._sharedConfig.trendTimeline);

        subscribeToStore(NAMESPACE.ROOT, 'trendTimeline', {
            mwcId,
            element: findTargetElement(mwcId, target),
            modelName: 'timeline'
        });
    }

    const timeSelector = timeline || _sharedConfig.trendTimeline;

    const parameters = {
        start: startFrom,
        duration,
        timelineKey: timeSelector,
        chunks: prepareTrendAnalysisChunks(mode, timeSelector)
    };

    if (!store[mwcId]) {
        store[mwcId] = {};
    }
    store[mwcId].parameters = parameters;

    const validMode = validString(mode, 'all').toLowerCase();
    let dataSourceId;

    switch (validMode) {
        case 'expenses':
        case 'expenses_and_budget':
            dataSourceId = DATA_SOURCE_IDS.TREND_ANALYSIS_STATISTICS_EXPENSES;
            break;
        case 'income':
            dataSourceId = DATA_SOURCE_IDS.TREND_ANALYSIS_STATISTICS_INCOME;
            break;
        case 'savings':
            dataSourceId = DATA_SOURCE_IDS.TREND_ANALYSIS_STATISTICS_SAVINGS;
            break;
        default:
            dataSourceId = DATA_SOURCE_IDS.TREND_ANALYSIS_STATISTICS_ALL;
            break;
    }

    await registerStatisticsStartMonth(apiSettings.getStatisticsStartMonth);
    await subscribeToStatisticsStartMonth({
        mwcId,
        target
    });

    await registerStatistics(dataSourceId, null, apiSettings.getStatistics);
    await subscribeToStatistics({
        dataSourceId,
        mwcId,
        target
    });
}

function onTimeSelectionChange(action, dataSources) {
    const { _sharedConfig } = dataSources;
    const { detail, target } = action;
    const {
        startFrom,
        duration,
        selectionKey
    } = detail[0] || {};

    if (_sharedConfig.trendTimeline) {
        updateStore(NAMESPACE.ROOT, 'trendTimeline', selectionKey);
    } else {
        target.timeline = selectionKey;
    }
    setElementLoading({
        target,
        loading: true
    });

    Object.keys(window.mwc.dataManager.dataSources)
        .filter(x => x.includes('ds-trend-analysis-statistics'))
        .forEach(dataSource => {
            const sourceTrendMode = TREND_MODES[dataSource];
            window.PFM_ORCHESTRATION.trigger('data-subscriber', {
                sourceTask: 'update',
                dataSourceId: dataSource,
                parameters: {
                    start: startFrom,
                    duration,
                    timelineKey: selectionKey,
                    chunks: prepareTrendAnalysisChunks(sourceTrendMode, selectionKey)
                }
            });
        });
}

async function onSaveChange(action, dataSources) {
    const { detail } = action;
    const {
        BUDGET,
        INCOME,
        SAVING
    } = detail[0] || {};

    if (isValidObject(BUDGET) || isValidNumber(INCOME) || isValidNumber(SAVING)) {
        const result = await saveBudgetIncomeSaving({
            BUDGET,
            INCOME,
            SAVING
        }, dataSources);

        if (result) {
            await refreshStatisticsSources();
            await window.PFM_ORCHESTRATION.changeUserStatus(USER_STATES.REPEAT_BUDGET);

            window.PFM_ORCHESTRATION.trigger('data-subscriber', {
                sourceTask: 'update',
                dataSourceId: 'ds-sync-budget-status'
            });
        }
    }
}

async function prepareTransactionTable(action, dataSources) {
    const { _sharedConfig, _route, ...apiSettings } = dataSources;
    const { detail, target } = action;
    const {
        id: mwcId,
        cashFlowType
    } = detail[0] || {};

    const parameters = {
        cashFlowType
    };

    const sourceSettings = cashFlowType === 'income'
        ? apiSettings.getIncomeTransactions
        : apiSettings.getExpenseTransactions;

    await registerCategories(apiSettings.getCategories);
    await subscribeToCategories({
        mwcId,
        target
    });

    await registerSource({
        parameters,
        fetchMechanism: getTransactionFactory(mwcId, target, sourceSettings),
        dataSourceId: _route,
        metadata: { userStates: [] }
    });
    await subscribeToSource({
        mwcId,
        dataSourceId: _route,
        element: findTargetElement(mwcId, target),
        modelName: 'transactionData',
        successFunction() {
            setElementLoading({
                mwcId,
                target,
                loading: false
            });
        },
        errorFunction() {
            setElementLoading({
                mwcId,
                target,
                loading: false
            });
        }
    });
}

async function prepareSavingsAccumulation(action, dataSources) {
    const { _sharedConfig, _route, ...apiSettings } = dataSources;
    const { detail, target } = action;
    const {
        id: mwcId
    } = detail[0] || {};
    const savingAccumulationParameters = {
        chunks: SAVING_ACCUMULATION_REQUEST
    };

    await registerAggregation(apiSettings.getAggregationSource);
    await subscribeToAggregation({
        mwcId,
        target
    });

    await registerStatistics(
        DATA_SOURCE_IDS.SAVINGS_ACCUMULATION_STATISTICS,
        savingAccumulationParameters,
        apiSettings.getStatistics
    );
    await subscribeToStatistics({
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.SAVINGS_ACCUMULATION_STATISTICS,
        modelName: 'savingAccumulationResponses'
    });

    await registerNetWorth(apiSettings.getNetWorthSource);
    await subscribeToNetWorth({
        mwcId,
        target,
        modelName: 'netWorthForSavingAccumulationResponses'
    });
}

async function prepareMonthlyBudget(action, dataSources) {
    const { _sharedConfig, _route, ...apiSettings } = dataSources;
    const { detail, target } = action;
    const {
        id: mwcId,
        timeSelected
    } = detail[0] || {};
    const { month, year } = timeSelected;
    const parameters = {
        chunks: getCashflowRequests({
            month,
            year
        }).cashflow_requests
    };

    if (!store[mwcId]) {
        store[mwcId] = {};
    }
    store[mwcId].parameters = parameters;

    await registerStatisticsStartMonth(apiSettings.getStatisticsStartMonth);
    await subscribeToStatisticsStartMonth({
        mwcId,
        target
    });

    await registerStatistics(
        DATA_SOURCE_IDS.MONTHLY_BUDGET_STATISTICS,
        parameters,
        apiSettings.getStatistics
    );
    await subscribeToStatistics({
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.MONTHLY_BUDGET_STATISTICS,
        modelName: 'budgetData'
    });
}

async function prepareCashflowOverview(action, dataSources) {
    const {
        _route,
        ...apiSettings
    } = dataSources;
    const {
        detail,
        target
    } = action;
    const {
        id: mwcId
    } = detail[0] || {};

    setElementLoading({
        mwcId,
        target,
        loading: true
    });

    const parameters = {
        chunks: EDIT_BUDGET_REQUEST
    };

    if (!store[mwcId]) {
        store[mwcId] = {};
    }
    store[mwcId].parameters = parameters;

    function getNotificationSource() {
        return function getNotifications(showNotifications) {
            return Promise.resolve(!!showNotifications);
        };
    }

    await registerStatistics(
        DATA_SOURCE_IDS.OVERVIEW_STATISTICS,
        parameters,
        apiSettings.getStatistics,
        [{
            name: USER_STATES.PRE_BUDGET,
            parameters: null
        }, {
            name: USER_STATES.FIRST_TIME_PRE_AGGREGATION,
            parameters: null
        }, {
            name: USER_STATES.FIRST_TIME_BUDGET,
            parameters: {
                chunks: CASHFLOW_REQUEST_CHUNKS.firstTimeUser
            }
        }, {
            name: USER_STATES.REPEAT_BUDGET,
            parameters: {
                chunks: CASHFLOW_REQUEST_CHUNKS.repeatUser
            }
        }]
    );
    await subscribeToStatistics({
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.OVERVIEW_STATISTICS,
        modelName: 'overviewResponses'
    });

    await registerStatistics(
        DATA_SOURCE_IDS.EDIT_MONTHLY_BUDGET_STATISTICS,
        null,
        apiSettings.getStatistics,
        [{
            name: USER_STATES.FIRST_TIME_PRE_AGGREGATION,
            parameters: null
        }, {
            name: USER_STATES.FIRST_TIME_BUDGET,
            parameters: {
                chunks: CASHFLOW_REQUEST_CHUNKS.firstTimeUser
            }
        }, {
            name: USER_STATES.REPEAT_BUDGET,
            parameters: null
        }]
    );
    await subscribeToStatistics({
        target,
        dataSourceId: DATA_SOURCE_IDS.EDIT_MONTHLY_BUDGET_STATISTICS,
        modelName: 'editBudgetData',
        mwcId: `${mwcId}.pfmEditBudgetSavingsTarget`
    });

    // setupUserStateNotification
    await registerSource({
        dataSourceId: 'ds-user-state-notification',
        fetchMechanism: getNotificationSource().bind(this),
        parameters: false,
        metadata: {
            userStates: [{
                name: USER_STATES.IMPORT_FAILURE,
                parameters: true
            }]
        }
    });
    await subscribeToSource({
        dataSourceId: 'ds-user-state-notification',
        element: findTargetElement(mwcId, target),
        modelName: 'userStateResponses',
        mwcId: `${mwcId}.userStateNotification`
    });

    await registerAggregation(apiSettings.getAggregationSource);
    await subscribeToAggregation({
        mwcId,
        target
    });

    await registerBudgetState();
    await subscribeToBudgetState({
        mwcId,
        target
    });
}

async function monthlyBudgetTimeSelector(action) {
    const { detail, target } = action;
    const { value } = detail[0] || {};
    const { month, year } = value;

    target.timeSelection = JSON.stringify({
        month,
        year
    });

    const parameters = {
        chunks: getCashflowRequests({
            month,
            year
        }).cashflow_requests
    };

    window.PFM_ORCHESTRATION.trigger('data-subscriber', {
        sourceTask: 'update',
        dataSourceId: DATA_SOURCE_IDS.MONTHLY_BUDGET_STATISTICS,
        parameters
    });
}

async function prepareLayout(action, dataSources) {
    const { _sharedConfig } = dataSources;
    const { detail, target } = action;
    const {
        id: mwcId
    } = detail;

    await registerBudgetStatus();
    await subscribeToBudgetStatus({
        mwcId,
        target
    });

    await registerUserConfigs(_sharedConfig.getUserConfigs);
    await subscribeToUserConfigs({
        mwcId,
        target
    });
}

export default {
    'pfm-layout-render': prepareLayout,
    'pfm-layout-destroy': noop,
    pfmTrendAnalysisAll: {
        'pfm-component-rendered': prepareTrendAnalysis,
        'pfm-component-destroyed': noop,
        'pfm-time-selection-change': onTimeSelectionChange
    },
    pfmTrendAnalysisSavings: {
        'pfm-component-rendered': prepareTrendAnalysis,
        'pfm-component-destroyed': noop,
        'pfm-time-selection-change': onTimeSelectionChange,
        'pfm-save-change': onSaveChange
    },
    pfmTrendAnalysisIncome: {
        'pfm-component-rendered': prepareTrendAnalysis,
        'pfm-component-destroyed': noop,
        'pfm-time-selection-change': onTimeSelectionChange
    },
    pfmTrendAnalysisExpenses: {
        'pfm-component-rendered': prepareTrendAnalysis,
        'pfm-component-destroyed': noop,
        'pfm-time-selection-change': onTimeSelectionChange,
        'pfm-save-change': onSaveChange
    },
    pfmMonthlyBudget: {
        'pfm-component-rendered': prepareMonthlyBudget,
        'pfm-component-destroyed': noop,
        'pfm-time-selection-change': monthlyBudgetTimeSelector,
        'pfm-save-change': onSaveChange
    },
    pfmCashflowTransactionTableExpenses: {
        'pfm-change-transaction-category': onChangeTransactionCategory,
        'pfm-change-transaction-inclusion': onChangeTransactionInclusion,
        'pfm-component-rendered': prepareTransactionTable,
        'pfm-component-destroyed': noop
    },
    pfmCashflowTransactionTableIncome: {
        'pfm-change-transaction-category': onChangeTransactionCategory,
        'pfm-change-transaction-inclusion': onChangeTransactionInclusion,
        'pfm-component-rendered': prepareTransactionTable,
        'pfm-component-destroyed': noop
    },
    pfmSavingsAccumulation: {
        'pfm-component-rendered': prepareSavingsAccumulation,
        'pfm-component-destroyed': noop,
        'pfm-navigation-click': noop
    },
    pfmCashflowOverview: {
        'pfm-analyze-spending': analyzeSpending,
        'pfm-component-destroyed': noop,
        'pfm-component-rendered': prepareCashflowOverview,
        'pfm-link-account': noop,
        'pfm-save-change': onSaveChange,
        'pfm-title-clicked': noop,
        'create-savings-target': onSaveChange
    }
};
