import { DATA_SOURCE_IDS, USER_STATES } from './constants';
import {
    getRequestFactory,
    getStatisticsFactory
} from './factories';
import { registerSource } from './helpers';

/**
 * @function registerBaaAccountUnlinked
 * @description Registers a source that handles BAA account unlink
 * @returns {void}
 */
export async function registerBaaAccountUnlinked() {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.BAA_ACCOUNT_UNLINKED,
        fetchMechanism: account => Promise.resolve(account),
        metadata: {
            userStates: []
        },
        parameters: null,
        useWorkflow: false
    });
}

/**
 * @function registerAggregation
 * @description Registers a source that retrieves accounts aggregated with BAA
 * @param {object} sourceSettings The settings object for the `aggregationFactory`
 * @returns {void}
 */
export async function registerAggregation(sourceSettings) {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.BAA_ACCOUNTS,
        fetchMechanism: getRequestFactory(sourceSettings),
        metadata: {
            userStates: []
        },
        parameters: null,
        useWorkflow: false
    });
}

/**
 * @function registerCategories
 * @description Registers a source that retrieves a complete list of categories
 * @param {object} sourceSettings The settings object for the `aggregationFactory`
 * @returns {void}
 */
export async function registerCategories(sourceSettings) {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.CATEGORIES,
        fetchMechanism: getRequestFactory(sourceSettings),
        metadata: {
            userStates: []
        },
        parameters: null,
        useWorkflow: false
    });
}

/**
 * @function registerNetWorth
 * @description Registers a source that retrieves net worth data
 * @param {object} sourceSettings The settings object for the `getNetWorthFactory`
 * @returns {void}
 */
export async function registerNetWorth(sourceSettings) {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.NET_WORTH_ACCOUNTS,
        fetchMechanism: getRequestFactory(sourceSettings),
        metadata: {
            userStates: [{
                name: USER_STATES.FIRST_TIME_AGGREGATION,
                parameters: null
            }, {
                name: USER_STATES.IMPORT_FAILURE,
                parameters: null
            }, {
                name: USER_STATES.PFM_READY,
                parameters: null
            }, {
                name: USER_STATES.ON_DEMAND_DATA_READY,
                parameters: null
            }, {
                name: USER_STATES.ON_DEMAND_AGGREGATION,
                parameters: null
            }],
            parameters: null
        }
    });
}

/**
 * @function registerNetWorthAccountDeleted
 * @description Registers a source that handles BAA account deletions
 * @returns {void}
 */
export async function registerNetWorthAccountDeleted() {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.NET_WORTH_ACCOUNT_DELETED,
        fetchMechanism: account => Promise.resolve(account),
        metadata: {
            userStates: []
        },
        parameters: null,
        useWorkflow: false
    });
}

/**
 * @function registerStatistics
 * @description Registers a source that retrieves statistics data
 * @param {string} dataSourceId The identifier for the data source
 * @param {object} parameters The parameters to pass to the API
 * @param {object} sourceSettings The settings object for the `getNetWorthFactory`
 * @param {array} userStates A collection of user states this data source cares about
 * @returns {void}
 */
export async function registerStatistics(dataSourceId, parameters, sourceSettings, userStates = []) {
    await registerSource({
        dataSourceId,
        parameters,
        fetchMechanism: getStatisticsFactory(sourceSettings),
        metadata: {
            userStates
        }
    });
}

/**
 * @function registerStatisticsStartMonth
 * @description Registers a source that retrieves start months for all statistics types
 * @param {object} sourceSettings The settings object for the `aggregationFactory`
 * @returns {void}
 */
export async function registerStatisticsStartMonth(sourceSettings) {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.STATISTICS_START_MONTH,
        fetchMechanism: getRequestFactory(sourceSettings),
        metadata: {
            userStates: []
        },
        parameters: null,
        useWorkflow: false
    });
}

/**
 * @function registerAccountsSyncing
 * @description Registers a source that tracks account syncing status
 * @returns {void}
 */
export async function registerAccountsSyncing() {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.SYNC_ACCOUNT_LIST,
        fetchMechanism: isSyncing => Promise.resolve(isSyncing),
        metadata: {
            userStates: [{
                name: USER_STATES.ON_DEMAND_DATA_READY,
                parameters: false
            }, {
                name: USER_STATES.ON_DEMAND_AGGREGATION,
                parameters: true
            }, {
                name: USER_STATES.ON_DEMAND_IMPORT,
                parameters: true
            }]
        },
        parameters: false
    });
}

/**
 * @function registerBudgetState
 * @description Registers a source that tracks budget state
 * @returns {void}
 */
export async function registerBudgetState() {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.SYNC_BUDGET_STATE,
        fetchMechanism: state => Promise.resolve(state),
        metadata: {
            userStates: [{
                name: USER_STATES.PRE_BUDGET,
                parameters: USER_STATES.PRE_BUDGET
            }, {
                name: USER_STATES.FIRST_TIME_BUDGET,
                parameters: USER_STATES.FIRST_TIME_BUDGET
            }, {
                name: USER_STATES.REPEAT_BUDGET,
                parameters: USER_STATES.REPEAT_BUDGET
            }]
        },
        parameters: USER_STATES.PRE_BUDGET
    });
}

/**
 * @function registerBudgetStatus
 * @description Registers a source that tracks budget status
 * @returns {void}
 */
export async function registerBudgetStatus() {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.SYNC_BUDGET_STATUS,
        fetchMechanism: isChanged => Promise.resolve(isChanged),
        metadata: {
            userStates: [{
                name: USER_STATES.PRE_BUDGET,
                parameters: false
            }, {
                name: USER_STATES.FIRST_TIME_BUDGET,
                parameters: true
            }, {
                name: USER_STATES.REPEAT_BUDGET,
                parameters: true
            }]
        },
        parameters: false
    });
}

/**
 * @function registerUserConfigs
 * @description Registers a source that retrieves user configurations
 * @param {object} sourceSettings The settings object for the `requestFactory`
 * @returns {void}
 */
export async function registerUserConfigs(sourceSettings) {
    await registerSource({
        dataSourceId: DATA_SOURCE_IDS.USER_CONFIGS,
        fetchMechanism: getRequestFactory(sourceSettings),
        metadata: {
            userStates: []
        },
        parameters: null,
        useWorkflow: false
    });
}

export default null;
