import {
    findTargetElement,
    setElementLoading
} from '../helpers/elements';
import { DATA_SOURCE_IDS } from './constants';
import { subscribeToSource } from './helpers';

/**
 * @function subscribeToAPISource
 * @description Boilerplate for subscribing to an API-based data source and setting element `loading` flag
 * @param {string} dataSourceID The name of the data source to subscribe to
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
async function subscribeToAPISource({
    dataSourceId,
    modelName,
    mwcId,
    target
}) {
    await subscribeToSource({
        dataSourceId,
        modelName,
        mwcId,
        element: findTargetElement(mwcId, target),
        errorFunction() {
            setElementLoading({
                mwcId,
                target,
                loading: false
            });
        },
        successFunction() {
            setElementLoading({
                mwcId,
                target,
                loading: false
            });
        }
    });
}

/**
 * @function subscribeToAccountsSyncing
 * @description Subscribes a target element to the sync status account list data source
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToAccountsSyncing({
    modelName = 'accountsSyncing',
    mwcId,
    target
}) {
    await subscribeToSource({
        modelName,
        mwcId,
        dataSourceId: DATA_SOURCE_IDS.SYNC_ACCOUNT_LIST,
        element: findTargetElement(mwcId, target)
    });
}

/**
 * @function subscribeToAggregation
 * @description Subscribes a target element to the BAA aggregation data source
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToAggregation({
    modelName = 'baaData',
    mwcId,
    target
}) {
    await subscribeToAPISource({
        modelName,
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.BAA_ACCOUNTS
    });
}

/**
 * @function subscribeToBaaAccountUnlinked
 * @description Subscribes a target element to BAA account unlinks
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToBaaAccountUnlinked({
    modelName = 'unlinkedBaaAccount',
    mwcId,
    target
}) {
    await subscribeToSource({
        modelName,
        mwcId,
        dataSourceId: DATA_SOURCE_IDS.BAA_ACCOUNT_UNLINKED,
        element: findTargetElement(mwcId, target)
    });
}

/**
 * @function subscribeToBudgetState
 * @description Subscribes a target element to the sync budget state data source
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToBudgetState({
    modelName = 'budgetState',
    mwcId,
    target
}) {
    await subscribeToSource({
        modelName,
        mwcId,
        dataSourceId: DATA_SOURCE_IDS.SYNC_BUDGET_STATE,
        element: findTargetElement(mwcId, target)
    });
}

/**
 * @function subscribeToBudgetStatus
 * @description Subscribes a target element to the sync status budget data source
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToBudgetStatus({
    modelName = 'budgetStatusChanged',
    mwcId,
    target
}) {
    await subscribeToSource({
        modelName,
        mwcId,
        dataSourceId: DATA_SOURCE_IDS.SYNC_BUDGET_STATUS,
        element: findTargetElement(mwcId, target)
    });
}

/**
 * @function subscribeToCategories
 * @description Subscribes a target element to the categories data source
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToCategories({
    modelName = 'categoriesData',
    mwcId,
    target
}) {
    await subscribeToAPISource({
        modelName,
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.CATEGORIES
    });
}

/**
 * @function subscribeToNetWorth
 * @description Subscribes a target element to the net worth data source
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToNetWorth({
    modelName = 'netWorthData',
    mwcId,
    target
}) {
    await subscribeToAPISource({
        modelName,
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.NET_WORTH_ACCOUNTS
    });
}

/**
 * @function subscribeToNetWorthAccountDeleted
 * @description Subscribes a target element to PFM/Office account deletions
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToNetWorthAccountDeleted({
    modelName = 'deletedNetWorthAccount',
    mwcId,
    target
}) {
    await subscribeToSource({
        modelName,
        mwcId,
        dataSourceId: DATA_SOURCE_IDS.NET_WORTH_ACCOUNT_DELETED,
        element: findTargetElement(mwcId, target)
    });
}

/**
 * @function subscribeToStatistics
 * @description Subscribes a target element to a statistics data source
 * @param {string} dataSourceID The name of the data source to subscribe to
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToStatistics({
    dataSourceId,
    modelName = 'chunkResponses',
    mwcId,
    target
}) {
    await subscribeToAPISource({
        dataSourceId,
        modelName,
        mwcId,
        target
    });
}

/**
 * @function subscribeToStatisticsStartMonth
 * @description Subscribes a target element to the statistics start month data source
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToStatisticsStartMonth({
    modelName = 'statisticsStartMonths',
    mwcId,
    target
}) {
    await subscribeToAPISource({
        modelName,
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.STATISTICS_START_MONTH
    });
}

/**
 * @function subscribeToUserConfigs
 * @description Subscribes a target element to the user configurations
 * @param {string} modelName The Vue prop to set when the data source is updated
 * @param {string} mwcId The MWC ID of the subscribing component
 * @param {object} target The target DOM element of the subscribing component
 * @returns {void}
 */
export async function subscribeToUserConfigs({
    modelName = 'userConfigs',
    mwcId,
    target
}) {
    await subscribeToAPISource({
        modelName,
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.USER_CONFIGS
    });
}

export default null;
