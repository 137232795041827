import {
    CASHFLOW_REQUEST_CHUNKS,
    EDIT_BUDGET_REQUEST
} from 'pfm-shared/helpers/cashflow';
import {
    findTargetElement,
    setElementLoading
} from 'pfm-shared/helpers/elements';
import {
    isValidNumber,
    isValidObject
} from 'pfm-shared/helpers/valid';
import { DATA_SOURCE_IDS, USER_STATES } from 'pfm-shared/sources/constants';
import {
    registerSource,
    subscribeToSource
} from 'pfm-shared/sources/helpers';
import {
    registerAggregation,
    registerBudgetState,
    registerStatistics
} from 'pfm-shared/sources/registrations';
import {
    subscribeToAggregation,
    subscribeToBudgetState,
    subscribeToStatistics
} from 'pfm-shared/sources/subscribers';
import {
    refreshStatisticsSources,
    saveBudgetIncomeSaving,
    setFirstTimeBudgetStatus
} from 'pfm-shared/sources/tasks';
import { VALUE_PERIOD } from 'pfm-shared/helpers/api';

const store = {};

function analyzeSpending() {
    setFirstTimeBudgetStatus();
}

function noop() {
    // Do nothing
}

async function onSaveChange(action, dataSources) {
    const { detail } = action;
    const {
        BUDGET,
        INCOME,
        SAVING
    } = detail[0] || {};

    if (isValidObject(BUDGET) || isValidNumber(INCOME) || isValidNumber(SAVING)) {
        const result = await saveBudgetIncomeSaving({
            BUDGET,
            INCOME,
            SAVING
        }, dataSources, VALUE_PERIOD.THREE_MONTHS);

        if (result) {
            await refreshStatisticsSources();
            await window.PFM_ORCHESTRATION.changeUserStatus(USER_STATES.REPEAT_BUDGET);

            window.PFM_ORCHESTRATION.trigger('data-subscriber', {
                sourceTask: 'update',
                dataSourceId: 'ds-sync-budget-status'
            });
        }
    }
}

async function prepareCashflowOverview(action, dataSources) {
    const {
        _route,
        ...apiSettings
    } = dataSources;
    const {
        detail,
        target
    } = action;
    const {
        id: mwcId
    } = detail[0] || {};

    setElementLoading({
        mwcId,
        target,
        loading: true
    });

    const parameters = {
        chunks: EDIT_BUDGET_REQUEST
    };

    if (!store[mwcId]) {
        store[mwcId] = {};
    }
    store[mwcId].parameters = parameters;

    function getNotificationSource() {
        return function getNotifications(showNotifications) {
            return Promise.resolve(!!showNotifications);
        };
    }

    await registerStatistics(
        DATA_SOURCE_IDS.OVERVIEW_STATISTICS,
        parameters,
        apiSettings.getStatistics,
        [{
            name: USER_STATES.PRE_BUDGET,
            parameters: null
        }, {
            name: USER_STATES.FIRST_TIME_PRE_AGGREGATION,
            parameters: null
        }, {
            name: USER_STATES.FIRST_TIME_BUDGET,
            parameters: {
                chunks: CASHFLOW_REQUEST_CHUNKS.firstTimeUser
            }
        }, {
            name: USER_STATES.REPEAT_BUDGET,
            parameters: {
                chunks: CASHFLOW_REQUEST_CHUNKS.repeatUser
            }
        }]
    );
    await subscribeToStatistics({
        mwcId,
        target,
        dataSourceId: DATA_SOURCE_IDS.OVERVIEW_STATISTICS,
        modelName: 'overviewResponses'
    });

    await registerStatistics(
        DATA_SOURCE_IDS.EDIT_MONTHLY_BUDGET_STATISTICS,
        null,
        apiSettings.getStatistics,
        [{
            name: USER_STATES.FIRST_TIME_PRE_AGGREGATION,
            parameters: null
        }, {
            name: USER_STATES.FIRST_TIME_BUDGET,
            parameters: {
                chunks: CASHFLOW_REQUEST_CHUNKS.firstTimeUser
            }
        }, {
            name: USER_STATES.REPEAT_BUDGET,
            parameters: null
        }]
    );
    await subscribeToStatistics({
        target,
        dataSourceId: DATA_SOURCE_IDS.EDIT_MONTHLY_BUDGET_STATISTICS,
        modelName: 'editBudgetData',
        mwcId: `${mwcId}.pfmEditBudgetSavingsTarget`
    });

    // setupUserStateNotification
    await registerSource({
        dataSourceId: 'ds-user-state-notification',
        fetchMechanism: getNotificationSource().bind(this),
        parameters: false,
        metadata: {
            userStates: [{
                name: USER_STATES.IMPORT_FAILURE,
                parameters: true
            }]
        }
    });
    await subscribeToSource({
        dataSourceId: 'ds-user-state-notification',
        element: findTargetElement(mwcId, target),
        modelName: 'userStateResponses',
        mwcId: `${mwcId}.userStateNotification`
    });

    await registerAggregation(apiSettings.getAggregationSource);
    await subscribeToAggregation({
        mwcId,
        target
    });

    await registerBudgetState();
    await subscribeToBudgetState({
        mwcId,
        target
    });
}

export default {
    'pfm-layout-render': noop,
    'pfm-layout-destroy': noop,
    pfmCashflowOverview: {
        'add-non-investment-accounts': noop,
        'create-savings-target': onSaveChange,
        'pfm-analyze-spending': analyzeSpending,
        'pfm-component-destroyed': noop,
        'pfm-component-rendered': prepareCashflowOverview,
        'pfm-save-change': onSaveChange,
        'pfm-title-clicked': noop
    }
};
