/**
 * @function createDatestamp
 * @description Creates a datestamp for the current date in the YYYY-MM format
 * @returns {string} The generated datestamp
 */
export function createDatestamp() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    return `${currentYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}`;
}

export default null;
