/**
 * @property {object} ACCEPT A collection of accept headers for API calls
 * @constant
 */
export const ACCEPT = {
    ALL: 'application/json, text/plain, *.*',
    JSON: 'application/json',
    TEXT: 'text/plain'
};

/**
 * @property {object} CONTENT_TYPE A collection of content type headers for API calls
 * @constant
 */
export const CONTENT_TYPE = {
    APPLICATION: 'application/octet-stream',
    JSON: 'application/json',
    TEXT: 'text/plain'
};

/**
 * @property {object} DATA_SOURCE_IDS A collection of shared data source IDs
 * @constant
 */
export const DATA_SOURCE_IDS = {
    BAA_ACCOUNT_UNLINKED: 'ds-baa-account-unlinked',
    BAA_ACCOUNTS: 'ds-baa-accounts',
    CATEGORIES: 'ds-categories',
    EDIT_MONTHLY_BUDGET_STATISTICS: 'ds-edit-monthly-budget-statistics',
    MONTHLY_BUDGET_STATISTICS: 'ds-monthly-budget-statistics',
    NET_WORTH_ACCOUNT_DELETED: 'ds-net-worth-account-deleted',
    NET_WORTH_ACCOUNTS: 'ds-net-worth-accounts',
    OVERVIEW_STATISTICS: 'ds-overview-statistics',
    SAVINGS_ACCUMULATION_STATISTICS: 'ds-savings-accumulation-statistics',
    STATISTICS_START_MONTH: 'ds-statistics-start-month',
    SYNC_ACCOUNT_LIST: 'ds-sync-status-account-list',
    SYNC_BUDGET_STATUS: 'ds-sync-budget-status',
    SYNC_BUDGET_STATE: 'ds-sync-budget-state',
    TREND_ANALYSIS_STATISTICS_ALL: 'ds-trend-analysis-statistics-all',
    TREND_ANALYSIS_STATISTICS_EXPENSES: 'ds-trend-analysis-statistics-expenses',
    TREND_ANALYSIS_STATISTICS_INCOME: 'ds-trend-analysis-statistics-income',
    TREND_ANALYSIS_STATISTICS_SAVINGS: 'ds-trend-analysis-statistics-savings',
    USER_CONFIGS: 'ds-user-configs',
    TRANSACTION_TABLE_EXPENSES: 'pfmLayoutCashflowDetails.pfmCashflowTransactionTableExpenses',
    TRANSACTION_TABLE_INCOME: 'pfmLayoutCashflowDetails.pfmCashflowTransactionTableIncome'
};

/**
 * @property {object} HTTP_METHOD A collection of methods for API calls
 * @constant
 */
export const HTTP_METHOD = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
    HEAD: 'head'
};

/**
 * @property {object} RESPONSE_TYPE A collection of response types for API calls
 * @constant
 */
export const RESPONSE_TYPE = {
    ARRAY_BUFFER: 'arraybuffer',
    BLOB: 'blob',
    DOCUMENT: 'document',
    JSON: 'json',
    TEXT: 'text',
    STREAM: 'stream'
};

/**
 * @property {object} SESSION_HEADERS A collection of headers for SAML sessions
 * @constant
 */
export const SESSION_HEADERS = {
    CSRFTOKEN: 'x-csrf-token',
    JSESSIONID: 'x-jsessionid'
};

/**
 * @property {array} STATISTICS_DATA_SOURCE_IDS A collection of statistics data source IDs
 * @constant
 */
export const STATISTICS_DATA_SOURCE_IDS = [
    DATA_SOURCE_IDS.EDIT_MONTHLY_BUDGET_STATISTICS,
    DATA_SOURCE_IDS.MONTHLY_BUDGET_STATISTICS,
    DATA_SOURCE_IDS.OVERVIEW_STATISTICS,
    DATA_SOURCE_IDS.SAVINGS_ACCUMULATION_STATISTICS,
    DATA_SOURCE_IDS.TREND_ANALYSIS_STATISTICS_ALL,
    DATA_SOURCE_IDS.TREND_ANALYSIS_STATISTICS_EXPENSES,
    DATA_SOURCE_IDS.TREND_ANALYSIS_STATISTICS_INCOME,
    DATA_SOURCE_IDS.TREND_ANALYSIS_STATISTICS_SAVINGS,
    DATA_SOURCE_IDS.TRANSACTION_TABLE_EXPENSES,
    DATA_SOURCE_IDS.TRANSACTION_TABLE_INCOME
];

/**
* @property {object} USER_STATES A collection of user states
* @constant
*/
export const USER_STATES = {
    FIRST_TIME_PRE_AGGREGATION: 'FIRST_TIME_PRE_AGGREGATION', // 'A1'
    FIRST_TIME_AGGREGATION: 'FIRST_TIME_AGGREGATION', // 'A2'
    FIRST_TIME_IMPORT: 'FIRST_TIME_IMPORT', // 'A3'
    IMPORT_FAILURE: 'IMPORT_FAILURE', // 'A4'
    PFM_READY: 'PFM_READY', // 'A5'
    PRE_BUDGET: 'PRE_BUDGET', // 'B1'
    FIRST_TIME_BUDGET: 'FIRST_TIME_BUDGET', // 'B2'
    REPEAT_BUDGET: 'REPEAT_BUDGET', // 'B3'
    ON_DEMAND_DATA_READY: 'ON_DEMAND_DATA_READY', // 'O1'
    ON_DEMAND_AGGREGATION: 'ON_DEMAND_AGGREGATION', // 'O2'
    ON_DEMAND_IMPORT: 'ON_DEMAND_IMPORT' // 'O3'
};

export default null;
