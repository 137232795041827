/**
 * @property {string} COUNTRY_CODE_REGEX A regular expression to test a country code
 * @const
 * @private
 */
const COUNTRY_CODE_REGEX = new RegExp('^([a-zA-Z]{2})((_|-){1}([a-zA-Z]{2})|)$', 'i');
/**
 * @property {array} FALSY_VALUES A collection of values considered `false`
 * @const
 * @private
 */
const FALSY_VALUES = [0, false, 'false', 'no'];
/**
 * @property {array} TRUTHY_VALUES A collection of values considered `true`
 * @const
 * @private
 */
const TRUTHY_VALUES = [1, true, 'true', 'yes'];

/**
 * @function isDocumentValid
 * @description Determines if the `document` object is available
 * @returns {boolean} Is `document` valid?
 */
export function isDocumentValid() {
    return typeof document === 'object' && document !== null;
}

/**
 * @function isFalsy
 * @description Quickly determine if a value is falsy
 * @param {object} value The value to verify
 * @returns {boolean} Was the value falsy?
 */
export function isFalsy(value) {
    return FALSY_VALUES.includes(value);
}

/**
 * @function isOfType
 * @description Checks that an object is of the desired type
 * @param {object} value The value to verify
 * @param {string} checkType The type to check the value against
 * @returns {boolean} Is the value of the requested type?
 */
export function isOfType(value, checkType) {
    // eslint-disable-next-line valid-typeof
    return typeof checkType === 'string' && typeof value === checkType;
}

/**
 * @function isTruthy
 * @description Quickly determine if a value is truthy
 * @param {object} value The value to verify
 * @returns {boolean} Was the value truthy?
 */
export function isTruthy(value) {
    return TRUTHY_VALUES.includes(value);
}

/**
 * @function isValidCountryCode
 * @description Determines if a value is a valid country code string
 * @param {object} countryCode The value to test as a valid country code
 * @returns {boolean} Was the input a valid country code?
 */
export function isValidCountryCode(countryCode) {
    if (typeof countryCode !== 'string') {
        return false;
    }

    return COUNTRY_CODE_REGEX.test(countryCode);
}

/**
 * @function isValidNumber
 * @description Determines that an item is a number, or can be parsed as such
 * @param {object} value The value to verify
 * @returns {boolean} Is the value a valid number?
 */
export function isValidNumber(value) {
    if (typeof value === 'number' && !Number.isNaN(value)) {
        return true;
    }

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(parseFloat(value))) {
        return true;
    }

    return false;
}

/**
 * @function isValidObject
 * @description Determines is of type `'object'` and non-`null`
 * @param {object} value The value to verify
 * @returns {boolean} Is the value a valid object?
 */
export function isValidObject(value) {
    return typeof value === 'object' && value !== null;
}

/**
 * @function isValidString
 * @description Determines is of type `'string'` and non-empty
 * @param {object} value The value to verify
 * @returns {boolean} Is the value a string?
 */
export function isValidString(value) {
    return typeof value === 'string' && value.trim() !== '';
}

/**
 * @function isWindowValid
 * @description Determines if the `window` object is available
 * @returns {boolean} Is `window` valid?
 */
export function isWindowValid() {
    return typeof window === 'object' && window !== null;
}

/**
 * @function validBoolean
 * @description Generates a valid boolean
 * @param {object} value The value to validate as a boolean
 * @param {object} defaultValue An optional default value to return
 * @returns {boolean} The resulting boolean value
 */
export function validBoolean(value, defaultValue) {
    if (typeof value === 'boolean') {
        return value;
    }

    if (isTruthy(value)) {
        return true;
    }

    if (isFalsy(value)) {
        return false;
    }

    if (typeof defaultValue === 'boolean') {
        return defaultValue;
    }

    if (isTruthy(defaultValue)) {
        return true;
    }

    if (isFalsy(defaultValue)) {
        return false;
    }

    return false;
}

/**
 * @function validFunction
 * @description Generates a valid function
 * @param {*} value The value to validate as a function
 * @returns {function} The resulting function
 */
export function validFunction(value) {
    if (typeof value === 'function') {
        return value;
    }

    return function defaultFn() {
        // Do nothing
    };
}

/**
 * @function validNumber
 * @description Generates a valid number
 * @param {object} value The value to validate as a number
 * @param {object} defaultValue An optional default value to return
 * @returns {number} The resulting number value
 */
export function validNumber(value, defaultValue) {
    // Attempt to call parseFloat if arguments are non-NaN
    try {
        const parseObj = parseFloat(value);
        const parseDefaultValue = parseFloat(defaultValue);

        // eslint-disable-next-line no-restricted-globals
        if (typeof value !== 'number' && !isNaN(parseObj)) {
            // eslint-disable-next-line no-param-reassign
            value = parseObj;
        }
        // eslint-disable-next-line no-restricted-globals
        if (typeof defaultValue !== 'number' && !isNaN(parseDefaultValue)) {
            // eslint-disable-next-line no-param-reassign
            defaultValue = parseDefaultValue;
        }
    } catch (e) {
        // Nothing to throw
    }

    if (typeof value === 'number' && !Number.isNaN(value)) {
        return value;
    }

    if (typeof defaultValue === 'number' && !Number.isNaN(value)) {
        return defaultValue;
    }

    return 0;
}

/**
 * @function validObject
 * @description Generates a valid object
 * @param {object} value The value to validate as an object-type
 * @param {object} defaultValue An optional default value to return
 * @returns {object} The resulting object
 */
export function validObject(value, defaultValue) {
    try {
        if (typeof value === 'string') {
            const parseObj = JSON.parse(value);
            // eslint-disable-next-line no-param-reassign
            value = parseObj;
        }
    } catch (e) {
        // Nothing to throw
    }

    try {
        if (typeof defaultValue === 'string') {
            const parseDefaultValue = JSON.parse(defaultValue);
            // eslint-disable-next-line no-param-reassign
            defaultValue = parseDefaultValue;
        }
    } catch (e) {
        // Nothing to throw
    }

    if (typeof value === 'object') {
        return value;
    }

    if (typeof defaultValue === 'object') {
        return defaultValue;
    }

    return null;
}

/**
 * @function validString
 * @description Generates a valid string
 * @param {object} value The value to validate as a string
 * @param {object} defaultValue An optional default value to return
 * @returns {string} The resulting string value
 */
export function validString(value, defaultValue) {
    if (typeof value !== 'undefined'
        && value !== null
        && typeof value.toString === 'function') {
        // eslint-disable-next-line no-param-reassign
        value = value.toString();
    }

    if (typeof defaultValue !== 'undefined'
        && defaultValue !== null
        && typeof defaultValue.toString === 'function') {
        // eslint-disable-next-line no-param-reassign
        defaultValue = defaultValue.toString();
    }

    if (typeof value === 'string') {
        return value;
    }

    if (typeof defaultValue === 'string') {
        return defaultValue;
    }

    return '';
}

/**
 * @function isValidArray
 * @description Determines if an object is an array-like
 * @param {object} value The value to verify
 * @returns {boolean} Is the value an array-like?
 */
export function isValidArray(value, minLength = 0) {
    let returnValue = false;

    if (typeof value !== 'object') {
        return false;
    }

    if ((Array.isArray(value) && value.length >= minLength)
    || (isValidObject(value) && typeof value.length === 'number')) {
        returnValue = true;
    }

    return returnValue;
}

/**
 * @function validArray
 * @description Generates a valid array
 * @param {object} value The value to validate as an Array
 * @param {object} defaultValue An optional default value to return
 * @returns {array} The resulting array
 */
export function validArray(value, defaultValue = []) {
    if (isValidArray(value)) {
        return value;
    }

    let parsedArray = null;

    try {
        parsedArray = Array.from(value);
        return parsedArray;
    } catch (e) {
        // Do nothing
    }

    if (isValidArray(defaultValue)) {
        return defaultValue;
    }

    let parsedDefaultArray = null;

    try {
        parsedDefaultArray = Array.from(defaultValue);
        return parsedDefaultArray;
    } catch (e) {
        // Do nothing
    }

    return [];
}

/**
 * @function whichString
 * @description Will fall through an array of items till it finds a valid string
 * @param {array} strings The array of items to traverse
 * @return {string} The first valid string found or an empty string
 */
export function whichString(strings = []) {
    if (!isValidArray(strings) || strings.length === 0) {
        return '';
    }

    let returnValue = '';

    for (let i = 0; i < strings.length; i += 1) {
        const stringValue = strings[i];

        if (isValidString(stringValue)) {
            returnValue = stringValue;
            break;
        }
    }

    return returnValue;
}

export default null;
