/* istanbul ignore file */
import { isDataAccessAvailable, request } from '../sources/helpers';

/**
 * @function getRequestService
 * @description Determines whether to use Axios (CWP) or MWC fetch (EC)
 * @todo This will eventually be deprecated when CWP consume PFM via EC
 * @return {object} The service {function} and type {string}
 */
export function getRequestService() {
    const dataAccessIsAvailable = isDataAccessAvailable();
    let returnObject = {
        service: fetch,
        type: 'fetch'
    };

    if (dataAccessIsAvailable === true) {
        returnObject = {
            service: request,
            type: 'mwc'
        };
    }

    return returnObject;
}

export default null;
