import { subMonths } from 'cwp-dev-shared/helpers/date';
import { formatDate } from 'cwp-dev-shared/helpers/formatter';
import {
    isValidArray,
    isValidObject,
    isValidString,
    validObject,
    validString
} from './valid';
import { generateMonthNames } from './charts';

/**
 * @todo Migrated from `pfm-helpers`
 * @todo Missing JSDocs will need to be added later
 */

/**
 * @property {object} CASH_FLOW_TYPE A collection of cash flow type names
 * @const
 */
export const CASH_FLOW_TYPE = {
    ANY: 'ANY',
    BUDGET: 'BUDGET',
    EXPENSE: 'EXPENSE',
    INCOME: 'INCOME',
    SAVING: 'SAVING',
    SAVING_TARGET: 'SAVING_TARGET',
    PROJECTED_INCOME: 'PROJECTED_INCOME',
    MONTHLY_UNDER_CATEGORY: 'MONTHLY_UNDER_CATEGORY',
    SAVING_TARGET_INCOME: 'SAVING_TARGET_INCOME',
    SAVING_INCOME: 'SAVING_INCOME',
    /* Custom types not supported by the server - ONLY for use on the client */
    /* These custom types SHOULD be handled by the method `extractValues` */
    DISCRETIONARY: 'discretionary',
    MANDATORY: 'mandatory',
    OTHER_INCOME: 'otherIncome',
    DEPOSITS: 'deposits',
    PAYCHECK: 'paycheck'
};

/**
 * @property {object} SORT_ORDER_KEY A collection of sort orders for transaction types?
 * @const
 */
export const SORT_ORDER_KEY = {
    discretionary: [
        'entertainment&restaurants',
        'luxury',
        'travel',
        'personalCare',
        'memberships',
        'otherExpenses'
    ],
    mandatory: [
        'insurance',
        'medical',
        'housing',
        'loans',
        'bills',
        'utilities',
        'transportation',
        'groceries',
        'childcare',
        'clothing'
    ]
};

/**
 * @property {object} VALUE_FORMAT A collection of value format strings
 * @const
 */
export const VALUE_FORMAT = {
    AVERAGE: 'AVERAGE',
    AVERAGE_UNDER_CATEGORY: 'AVERAGE_UNDER_CATEGORY',
    MONTHLY: 'MONTHLY',
    MONTHLY_UNDER_CATEGORY: 'MONTHLY_UNDER_CATEGORY',
    SUM: 'SUM',
    SUM_UNDER_CATEGORY: 'SUM_UNDER_CATEGORY',
    RATIO: 'RATIO',
    RECENT_MONTH: 'RECENT_MONTH'
};

/**
 * @property {object} VALUE_PERIOD A collection of value periods
 * @const
 */
export const VALUE_PERIOD = {
    RECENT_MONTH: 0,
    THREE_MONTHS: 3,
    SIX_MONTHS: 6,
    ONE_YEAR: 12,
    TWO_YEARS: 24,
    THREE_YEARS: 36,
    LAST_3_MONTHS: 3,
    LAST_6_MONTHS: 6,
    LAST_1_YEAR: 12,
    LAST_CALENDAR_YEAR: () => (new Date()).getMonth(),
    YTD: () => (new Date()).getMonth()
};

/**
 * @function getHostNames
 * @description Programmatically determines PFM/BAA host names based on a `pfmApiEnv` configuration
 * @param {string} pfmApiEnv The configured environment
 * @returns {object} A collection of host names for the environment
 */
export function getHostNames(pfmApiEnv) {
    // This is the REST endpoint for ByAllAccounts aggregation calls
    let baaMaasDomain = 'https://www.us-api.morningstar.com';
    // This is the ByAllAccounts domain that serves the custom element UI
    let aggregationHost = 'https://www.byallaccounts.net';
    // This is the REST endpoint for PFM calls
    let cwpDomain = 'https://www.us-api.morningstar.com/personal-financial-management';

    switch (pfmApiEnv) {
        case 'local':
            baaMaasDomain = 'https://www.us-stg-api.morningstar.com';
            aggregationHost = 'https://basrvstgap6001.morningstar.com';
            cwpDomain = 'https://local.morningstar.com';
            break;
        case 'dev':
            baaMaasDomain = 'https://www.us-stg-api.morningstar.com';
            aggregationHost = 'https://basrvstgap6001.morningstar.com';
            cwpDomain = 'https://www.us-dev-api.morningstar.com/personal-financial-management';
            break;
        case 'qa':
            baaMaasDomain = 'https://www.us-stg-api.morningstar.com';
            aggregationHost = 'https://basrvstgap6001.morningstar.com';
            cwpDomain = 'https://www.us-qa-api.morningstar.com/personal-financial-management';
            break;
        case 'stage':
        case 'stg':
            baaMaasDomain = 'https://www.us-stg-api.morningstar.com';
            aggregationHost = 'https://basrvstgap6001.morningstar.com';
            cwpDomain = 'https://www.us-stg-api.morningstar.com/personal-financial-management';
            break;
        case 'uat':
            cwpDomain = 'https://www.us-uat-api.morningstar.com/personal-financial-management';
            break;
        case 'tp':
            cwpDomain = 'https://cwp-webapp-tp.fpf1779.eas.morningstar.com';
            break;
        default:
            break;
    }

    const aggregationAPI = `${baaMaasDomain}/aggapi/v1`;
    const pfmMaasDomain = `${cwpDomain}/api/v1`;

    return {
        aggregationAPI,
        aggregationHost,
        baaMaasDomain,
        cwpDomain,
        pfmMaasDomain
    };
}

/**
 * @function getPeriod
 * @description Attempts to retrieve a value period by its name
 * @param {string} period The period name to look up
 * @returns {number} The calculated period
 */
export function getPeriod(period) {
    const valuePeriod = VALUE_PERIOD[period] === 0
        ? VALUE_PERIOD[period]
        : VALUE_PERIOD[period] || period;

    return typeof valuePeriod === 'function'
        ? valuePeriod()
        : valuePeriod;
}

/**
 * @function selectFormatTypeForSubcategory
 * @description Determines a subcategory format type
 * @param {string} format The format to look up
 * @returns {string} The value format type
 */
export function selectFormatTypeForSubcategory(format) {
    if (!isValidString(format)) {
        return format;
    }

    switch (format) {
        case VALUE_FORMAT.SUM:
            return VALUE_FORMAT.SUM_UNDER_CATEGORY;
        case VALUE_FORMAT.AVERAGE:
            return VALUE_FORMAT.AVERAGE_UNDER_CATEGORY;
        case VALUE_FORMAT.MONTHLY:
            return VALUE_FORMAT.MONTHLY_UNDER_CATEGORY;
        default:
            return format;
    }
}

/**
 * @function lookupChunks
 * @description Attempts to find chunks meeting desired rules
 * @param {array} receivedChunks The array of chunks to search
 * @param {string} receivedCashflowType The cashflow type to filter by
 * @param {string} receivedFormat The format to filter by
 * @returns {object} The matching chunks
 */
export function lookupChunks(receivedChunks, receivedCashflowType, receivedFormat) {
    const cashflowType = validString(receivedCashflowType, '');
    const chunks = validObject(receivedChunks, []);
    const format = validString(receivedFormat, VALUE_FORMAT.MONTHLY);

    if (!isValidArray(chunks, 1)) {
        return null;
    }

    return chunks.find(chunk => chunk.cashFlowType === cashflowType
        && chunk.resultType === format
        && chunk.period !== VALUE_FORMAT.RECENT_MONTH);
}

/**
 * @function generateMonthKeys
 * @description Attempts to generate month keys for a given period
 * @param {string} period The period name to look up
 * @returns {array} The generated months
 */
export function generateMonthKeys(period) {
    if (!isValidString(period)) {
        return [];
    }

    const numberOfMonths = getPeriod(period);
    const months = [];
    const monthFormat = 'YYYY-MM';

    if (numberOfMonths >= 1) {
        for (let i = numberOfMonths; i >= 1; i -= 1) {
            const month = formatDate(subMonths(new Date(), i), false, '', {}, monthFormat);
            months.push(month);
        }
    } else {
        months.push(formatDate(new Date(), false, '', {}, monthFormat));
    }

    return months;
}

export function extractSubCategoryValues(chunk, subCategory, format) {
    if (!isValidObject(chunk) || !isValidObject(chunk.value)) {
        return null;
    }

    switch (format) {
        case VALUE_FORMAT.AVERAGE_UNDER_CATEGORY:
        case VALUE_FORMAT.SUM_UNDER_CATEGORY:
            return chunk.value && chunk.value[subCategory];
        case VALUE_FORMAT.MONTHLY_UNDER_CATEGORY: {
            if (isValidArray(chunk.value)) {
                return chunk.value.map(month => month[subCategory]);
            }

            const filteredChunks = {
                ...chunk.value
            };

            Object.keys(chunk.value).forEach(key => {
                filteredChunks[key] = (chunk.value[key] && chunk.value[key][subCategory]) || 0;
            });

            return filteredChunks;
        }
        default: {
            return chunk.value;
        }
    }
}

export function lookupChunksForCustomTypes(
    receivedChunks,
    receivedCashflowType,
    receivedFormat
) {
    const cashflowType = validString(receivedCashflowType, '');
    const chunks = validObject(receivedChunks, []);
    const format = validString(receivedFormat, VALUE_FORMAT.MONTHLY);

    let matchingChunk;
    let subCategoryFormat;

    // custom lookup logic
    switch (cashflowType) {
        case CASH_FLOW_TYPE.DISCRETIONARY:
        case CASH_FLOW_TYPE.MANDATORY:
            subCategoryFormat = selectFormatTypeForSubcategory(format);
            matchingChunk = lookupChunks(
                chunks,
                CASH_FLOW_TYPE.EXPENSE,
                subCategoryFormat
            );
            if (validObject(matchingChunk) && validObject(matchingChunk.value)) {
                matchingChunk = {
                    ...matchingChunk,
                    value: extractSubCategoryValues(matchingChunk, cashflowType, subCategoryFormat)
                };
            }
            break;
        case CASH_FLOW_TYPE.OTHER_INCOME:
        case CASH_FLOW_TYPE.DEPOSITS:
        case CASH_FLOW_TYPE.PAYCHECK:
            subCategoryFormat = selectFormatTypeForSubcategory(format);
            matchingChunk = lookupChunks(
                chunks,
                CASH_FLOW_TYPE.INCOME,
                subCategoryFormat
            );
            if (validObject(matchingChunk) && validObject(matchingChunk.value)) {
                matchingChunk = {
                    ...matchingChunk,
                    value: extractSubCategoryValues(matchingChunk, cashflowType, subCategoryFormat)
                };
            }
            break;
        default:
    }

    return matchingChunk;
}

export function generateMonthName(param, duration) {
    if (!isValidArray(param) || !isValidObject(param)) {
        return null;
    }

    let yearList = [];
    let monthList = [];

    if (param instanceof Array) {
        const {
            names,
            labels
        } = generateMonthNames(duration);

        monthList = names;
        yearList = labels;
    } else if (typeof param === 'object') {
        Object.keys(param).forEach((val, key) => {
            const dateVal = val.split('-');
            const labels = key === 0 || dateVal[1] === '01' ? dateVal[0] : null;
            yearList.push(labels);
            monthList.push(formatDate(`${dateVal[0]}-${dateVal[1]}-01`, false, '', {}, 'MMM'));
        });
    }

    return {
        monthList,
        yearList
    };
}

export function getValues(param, paramKey) {
    if (!isValidObject(param)) {
        return null;
    }

    if (paramKey) {
        const tempArr = [];

        paramKey.forEach(key => {
            tempArr.push((param[key] !== undefined) ? param[key] : null);
        });

        return tempArr;
    }

    return typeof param === 'object' ? Object.values(param) : param;
}

export function extractValues(chunks = [], cashflowType, format = VALUE_FORMAT.MONTHLY) {
    if (!isValidString(cashflowType)) {
        return null;
    }

    let customTypeFlag = false;
    // 1. Lookup by name (direct lookup)
    let matchingChunk = lookupChunks(chunks, cashflowType, format);

    // 2. Custom lookup (for types not supported by the server)
    if (!isValidObject(matchingChunk)) {
        matchingChunk = lookupChunksForCustomTypes(chunks, cashflowType, format);
        customTypeFlag = true;
    }

    // 3. Global lookup using the ANY chunk (if available) [parameter "cashflowType" is used as a key]
    if (!isValidObject(matchingChunk)) {
        const allDataPoints = chunks.find(chunk => chunk.cashFlowType === CASH_FLOW_TYPE.ANY);

        if (isValidObject(allDataPoints)) {
            matchingChunk = {
                value: allDataPoints.value.map(month => month[cashflowType])
            };
        }
    }

    // 4. Merge recent month for chart data
    if (isValidObject(matchingChunk) && matchingChunk.addRecentMonth) {
        let recentObj = chunks.find(chunk => chunk.cashFlowType === matchingChunk.cashFlowType
            && chunk.resultType === matchingChunk.resultType
            && chunk.period === VALUE_FORMAT.RECENT_MONTH);

        if (isValidObject(recentObj) && isValidObject(recentObj.value)) {
            recentObj = recentObj.value;

            if (customTypeFlag) {
                const key = Object.keys(recentObj)[0];
                recentObj = {
                    [key]: recentObj[key][cashflowType]
                };
            }

            if (!isValidObject(matchingChunk.value)) {
                matchingChunk.value = {};
            }
            Object.assign(matchingChunk.value, recentObj);
        }
    }

    return matchingChunk && matchingChunk.value;
}

export default null;
