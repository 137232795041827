(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue-custom-element"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["vue-custom-element", "vue"], factory);
	else if(typeof exports === 'object')
		exports["pfm-communicator"] = factory(require("vue-custom-element"), require("vue"));
	else
		root["pfmCommunicator"] = factory(root["VueCustomElement"], root["Vue"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2476__, __WEBPACK_EXTERNAL_MODULE__8bbf__) {
return 