/* istanbul ignore file */
import 'core-js/stable';
import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';
import { mwcComponentName } from '../package.json';
import Component from './pfm-communicator';

export default async function init() {
    if (!Vue.customElement) {
        Vue.use(VueCustomElement);
    }

    Vue.config.ignoredElements.push(mwcComponentName);
    Vue.customElement(mwcComponentName, Component);

    return window.customElements.get(mwcComponentName);
}
