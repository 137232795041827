import { isValidObject, isValidString, validNumber } from './valid';

export function getOrdinalValue(value, languageId) {
    const parsedValue = validNumber(value, -1);

    if (parsedValue === -1) {
        return 'other';
    }

    if (typeof Intl.PluralRules === 'function') {
        const ordinalNumberRule = new Intl.PluralRules(
            languageId || 'en',
            {
                type: 'ordinal'
            }
        );

        return ordinalNumberRule.select(parsedValue);
    }

    // If Intl.PluralRules not supported, use english algorithm
    switch (parsedValue.toString().slice(-1)) {
        case '0':
            return 'zero';
        case '1':
            return parsedValue.toString().slice(-2) === '11'
                ? 'many'
                : 'one';
        case '2':
            return parsedValue.toString().slice(-2) === '12'
                ? 'many'
                : 'two';
        case '3':
            return 'few';
        case '4':
            return 'many';
        default:
            return 'other';
    }
}

/**
 * @function resolveBits
 * @description Traverses the nodes or "bits" of an object to find items of the expected type
 * @param {string} bits The dot-notated "bits" to traverse
 * @param {string} expectedType The `typeof` expected at the end of the node tree
 * @param {object} root The root object to traverse
 * @returns {*} The located element or `null`
 */
export function resolveBits({
    bits = '',
    expectedType = 'object',
    root = window
}) {
    if (!isValidString(bits) || !bits.includes('.')) {
        return null;
    }

    const bitNames = bits.split('.');
    const bitNamesCount = bitNames.length;

    let FinalReference = isValidObject(root) ? root : window;
    let bitName = '';

    for (let i = 0; i < bitNamesCount; i += 1) {
        bitName = bitNames[i];

        try {
            FinalReference = FinalReference[bitName];
        } catch (e) {
            FinalReference = null;
            break;
        }
    }

    // eslint-disable-next-line valid-typeof
    if (typeof FinalReference !== expectedType) {
        FinalReference = null;
    }

    return FinalReference;
}

export default null;
