import { CASH_FLOW_TYPE, VALUE_FORMAT, getPeriod } from './api';

export const TREND_MODE = {
    ALL: 'all',
    SAVINGS: 'savings',
    INCOME: 'income',
    EXPENSES_AND_BUDGET: 'expenses_and_budget'
};

/**
 * @function prepareTrendAnalysisChunks
 * @description Determines which request chunks to pass based on trend mode
 * @param {string} mode The trend mode to prepare for
 * @param {string} duration The duration for period lookup
 * @returns {array} The chunks
 */
export function prepareTrendAnalysisChunks(mode, duration) {
    const period = getPeriod(duration);
    const addRecentMonth = true;
    const lastCalendarYear = duration === 'LAST_CALENDAR_YEAR';
    const recentMonth = 0;

    switch (mode) {
        case TREND_MODE.ALL:
            return [{
                cashFlowType: CASH_FLOW_TYPE.INCOME,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.INCOME,
                resultType: VALUE_FORMAT.SUM,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.INCOME,
                resultType: VALUE_FORMAT.MONTHLY,
                period,
                lastCalendarYear,
                addRecentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.SUM,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.MONTHLY,
                period,
                lastCalendarYear,
                addRecentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING_TARGET,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING,
                resultType: VALUE_FORMAT.SUM,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING,
                resultType: VALUE_FORMAT.MONTHLY,
                period,
                lastCalendarYear,
                addRecentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.INCOME,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }];
        case TREND_MODE.SAVINGS:
            return [{
                cashFlowType: CASH_FLOW_TYPE.SAVING,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING,
                resultType: VALUE_FORMAT.SUM,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING,
                resultType: VALUE_FORMAT.MONTHLY,
                period,
                lastCalendarYear,
                addRecentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING_TARGET_INCOME,
                resultType: VALUE_FORMAT.RATIO,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING_INCOME,
                resultType: VALUE_FORMAT.RATIO,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING_TARGET,
                resultType: VALUE_FORMAT.MONTHLY,
                period,
                lastCalendarYear,
                addRecentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING_TARGET,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING_TARGET,
                resultType: VALUE_FORMAT.SUM,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.MONTHLY_UNDER_CATEGORY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.PROJECTED_INCOME,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING_TARGET,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.BUDGET,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.BUDGET,
                resultType: VALUE_FORMAT.MONTHLY_UNDER_CATEGORY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }];
        case TREND_MODE.INCOME:
            return [{
                cashFlowType: CASH_FLOW_TYPE.INCOME,
                resultType: VALUE_FORMAT.MONTHLY_UNDER_CATEGORY,
                period,
                lastCalendarYear,
                addRecentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.INCOME,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.INCOME,
                resultType: VALUE_FORMAT.SUM,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.INCOME,
                resultType: VALUE_FORMAT.MONTHLY_UNDER_CATEGORY,
                period: recentMonth
            }];
        case TREND_MODE.EXPENSES_AND_BUDGET:
            return [{
                cashFlowType: CASH_FLOW_TYPE.BUDGET,
                resultType: VALUE_FORMAT.MONTHLY,
                period,
                lastCalendarYear,
                addRecentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.BUDGET,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.BUDGET,
                resultType: VALUE_FORMAT.SUM,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.AVERAGE,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.SUM,
                period,
                lastCalendarYear
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.MONTHLY_UNDER_CATEGORY,
                period,
                lastCalendarYear,
                addRecentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.MONTHLY_UNDER_CATEGORY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.PROJECTED_INCOME,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.SAVING_TARGET,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.BUDGET,
                resultType: VALUE_FORMAT.MONTHLY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.BUDGET,
                resultType: VALUE_FORMAT.MONTHLY_UNDER_CATEGORY,
                period: recentMonth
            }, {
                cashFlowType: CASH_FLOW_TYPE.EXPENSE,
                resultType: VALUE_FORMAT.MONTHLY_UNDER_CATEGORY,
                period: recentMonth
            }];
        default:
            return [];
    }
}

export default null;
