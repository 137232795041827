<template>
    <div class="pfm-communicator" :data-mwc-id="mwcId"></div>
</template>

<script>
import { getHostNames } from 'pfm-shared/helpers/api';
import { resolveBits } from 'pfm-shared/helpers/data';
import { isValidElement } from 'pfm-shared/helpers/elements';
import { getEventHub } from 'pfm-shared/helpers/event-hub';
import {
    isOfType,
    isValidString,
    isValidObject,
    validObject
} from 'pfm-shared/helpers/valid';
import pfmLayoutAccountList from './routes/pfm-layout-account-list';
import pfmLayoutOverview from './routes/pfm-layout-overview';
import pfmLayoutCashflowDetails from './routes/pfm-layout-cashflow-details';

function isBAA(url) {
    return isValidString(url) && url.indexOf('aggapi/v1') > -1;
}

function isPFM(url) {
    return isValidString(url) && url.indexOf('personal-financial-management') > -1;
}

function updateURL(url, pfmApiEnv) {
    if (!isValidString(url)) {
        return url;
    }

    const isAbsolute = url.indexOf('http:') === 0 || url.indexOf('https:') === 0;
    const isBAAURL = isBAA(url);
    const isPFMURL = isPFM(url);
    const { baaMaasDomain, pfmMaasDomain } = getHostNames(pfmApiEnv);

    if (isAbsolute) {
        return url;
    }

    if (isBAAURL) {
        return `${baaMaasDomain}${url}`;
    }

    if (isPFMURL) {
        return `${pfmMaasDomain}${url}`;
    }

    return url;
}

function setSourceURLs(sourceNode, pfmApiEnv) {
    const sourceNames = Object.keys(sourceNode);

    sourceNames.forEach(sourceName => {
        const node = sourceNode[sourceName];

        if (isValidObject(node) && isValidString(node.url)) {
            node.url = updateURL(node.url, pfmApiEnv);
        }
    });
}

export default {
    computed: {
        dataSourcesParsed() {
            const parsedDataSources = validObject(this.dataSources, {});
            const updatedDataSources = this.setAbsoluteDataSourceUrls({ ...parsedDataSources });

            return updatedDataSources;
        },
        eventHub() {
            return getEventHub(this.parentMwcId);
        }
    },
    async created() {
        const { mwcId, eventHub, isAccountListComponent } = this;

        window.PFM_ORCHESTRATION.setPfmServerConfig(this.dataSourcesParsed.root.pfmServerConfig, isAccountListComponent);

        if (!eventHub || typeof eventHub.$on !== 'function') {
            return;
        }

        eventHub.$on('mstar-experience-manager', this.onMessage);
        eventHub.$emit('middleware-ready', mwcId);
    },
    data() {
        return {
            ROUTE_MAP: {
                pfmLayoutAccountList,
                pfmLayoutOverview,
                pfmLayoutCashflowDetails
            },
            namespace: 'pfm-communicator',
            token: false
        };
    },
    methods: {
        /**
         * @function functionLookup
         * @description Attempts to locate a handler function for an event
         * @param {string} route The route to look in
         * @param {object} action The event that triggered the lookup
         * @returns {*} The function or false
         */
        functionLookup(route, action) {
            const bits = `${route}.${action.type}`;
            const resolved = resolveBits({
                bits,
                expectedType: 'function',
                root: this.ROUTE_MAP
            });

            return resolved;
        },
        async onMessage(evt) {
            const { route, action } = evt;
            const dataSources = this.sourceLookup(route);
            const fn = this.functionLookup(route, action);

            if (isOfType(fn, 'function')) {
                fn.call(this, action, dataSources);
            }

            this.triggerParentEvent('mstar-experience-manager', action);
        },
        /**
         * @function setAbsoluteDataSourceUrls
         * @description Updates relative source URLs to be absolute URLs for PFM_ORCHESTRATION
         * @param {object} dataSources A collection of data source objects
         * @return {object} A collection of updated data source objects
         */
        setAbsoluteDataSourceUrls(dataSources) {
            const { pfmApiEnv } = this;
            const updatedDataSources = { ...dataSources };
            const dataSourceNodeNames = Object.keys(updatedDataSources);

            dataSourceNodeNames.forEach(nodeName => {
                const rootNode = updatedDataSources[nodeName];
                const childNodeNames = Object.keys(rootNode);

                childNodeNames.forEach(childNodeName => {
                    const childNode = rootNode[childNodeName];
                    setSourceURLs(childNode, pfmApiEnv);
                });
            });

            return updatedDataSources;
        },
        sourceLookup(bits) {
            const { dataSourcesParsed } = this;
            const dataSources = validObject(resolveBits({
                bits,
                root: dataSourcesParsed
            }), {});
            const topLevel = bits.split('.')[0];
            const sharedConfigBits = `${topLevel}.sharedConfig`;
            const sharedConfig = validObject(resolveBits({
                bits: sharedConfigBits,
                root: dataSourcesParsed
            }), {});

            return {
                _route: bits,
                _sharedConfig: sharedConfig,
                ...dataSources
            };
        },
        triggerParentEvent(eventName = 'mstar-experience-manager', body = {}) {
            const { $el } = this;

            if (isValidElement($el.parentElement)) {
                $el.parentElement.dispatchEvent(new CustomEvent(eventName, {
                    bubbles: true,
                    detail: [body]
                }));
            } else {
                this.$emit(eventName, body);
            }
        }
    },
    name: 'pfm-communicator',
    props: {
        dataSources: {
            type: String
        },
        mwcId: {
            required: true,
            type: String
        },
        parentMwcId: {
            required: false,
            type: String
        },
        pfmApiEnv: {
            default: 'prod',
            type: String
        },
        isAccountListComponent: {
            default: false,
            type: Boolean
        }
    }
};
</script>

<style lang="scss" module>
:global {
    .pfm-communicator {
        display: none;
    }
}
</style>
