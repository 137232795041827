import {
    isValidString,
    isValidNumber
} from 'pfm-shared/helpers/valid';
import {
    isValidElement,
    setElementLoading,
    screenReaderStatus
} from 'pfm-shared/helpers/elements';
import {
    registerAggregation,
    registerBaaAccountUnlinked,
    registerNetWorth,
    registerNetWorthAccountDeleted,
    registerAccountsSyncing
} from 'pfm-shared/sources/registrations';
import {
    subscribeToAggregation,
    subscribeToBaaAccountUnlinked,
    subscribeToNetWorth,
    subscribeToNetWorthAccountDeleted,
    subscribeToAccountsSyncing
} from 'pfm-shared/sources/subscribers';
import {
    deleteBaaCredential,
    deleteOfficeAccount,
    deletePfmAccount,
    resetAggregationStatus,
    triggerBaaAccountUnlink,
    triggerBaaUpdate,
    triggerNetWorthAccountDelete,
    triggerNetWorthUpdate,
    unlinkBaaAccount
} from 'pfm-shared/sources/tasks';

function noop() {
    // Do nothing
}

function showSupportModal(action) {
    const { detail } = action;
    const accountDetail = detail[0] || {};

    this.triggerParentEvent('pfm-show-support-modal', accountDetail);
}

async function prepareSources(action, dataSources) {
    const { _sharedConfig, _route, ...apiSettings } = dataSources;
    const { detail, target } = action;
    const {
        id: mwcId
    } = detail[0];

    await registerAccountsSyncing();
    await subscribeToAccountsSyncing({
        mwcId,
        target
    });

    await registerAggregation(apiSettings.getAggregationSource);
    await subscribeToAggregation({
        mwcId,
        target
    });

    await registerBaaAccountUnlinked();
    await subscribeToBaaAccountUnlinked({
        mwcId,
        target
    });

    await registerNetWorth(apiSettings.getNetWorthSource);
    await subscribeToNetWorth({
        mwcId,
        target
    });

    await registerNetWorthAccountDeleted();
    await subscribeToNetWorthAccountDeleted({
        mwcId,
        target
    });
}

function addedCredential(action) {
    const { detail, target } = action;
    const {
        id: mwcId
    } = detail[0];

    setElementLoading({
        mwcId,
        target,
        loading: true
    });
    triggerBaaUpdate();
}

/**
 * @function deleteCredential
 * @description Deletes a credential (institution log in) from ByAllAccounts
 * @param {object} action Event data from the event that triggered this call, we need `action.id`
 * @param {object} dataSources The collection of data sources configured for the component
 * @returns {void}
 */
function deleteCredential(action, dataSources) {
    const { _sharedConfig, _route, ...apiSettings } = dataSources;
    const { detail } = action;
    const {
        id: credentialId
    } = detail[0];
    const { deleteCredentialSource } = apiSettings;

    deleteBaaCredential(credentialId, deleteCredentialSource);
}

/**
 * @function deleteNetWorthAccount
 * @description Deletes an unlinked account using its `importExternalId`
 * @param {object} action Event data from the event that triggered this call, we need `action.accountId`
 * @param {object} dataSources The collection of data sources configured for the component
 * @returns {void}
 */
async function deleteNetWorthAccount(action, dataSources) {
    const { _sharedConfig, _route, ...apiSettings } = dataSources;
    const { detail } = action;
    const account = detail[0];
    const {
        accountNumber,
        displayType,
        financialItemId,
        financialItemType,
        importExternalId
    } = account;

    const {
        deleteOfficeAccountSource,
        deletePfmAccountSource
    } = apiSettings;
    let dataChanged = false;

    if (isValidString(financialItemId) && isValidNumber(financialItemType)) {
        await deleteOfficeAccount({
            financialItemId,
            financialItemType,
            sourceSettings: deleteOfficeAccountSource
        });
        dataChanged = true;
    }

    if (isValidString(importExternalId)) {
        await deletePfmAccount(importExternalId, deletePfmAccountSource);
        dataChanged = true;
    }

    if (dataChanged) {
        screenReaderStatus(`Account XXXXXXXXX${accountNumber} of type ${displayType} is deleted.`);
        await triggerNetWorthUpdate();
        await triggerNetWorthAccountDelete(account);
    }
}

async function triggerAggregation(action) {
    const callback = function triggerAggregationCallback({ response }) {
        const { target } = action;

        if (isValidElement(target)) {
            const eventName = response === false
                ? 'pfm-account-aggregation-failed'
                : 'pfm-account-aggregation-succeeded';
            const customEvent = new CustomEvent(eventName, {
                detail: {}
            });
            target.dispatchEvent(customEvent);
        }
    };

    // With MWC Application Helper 2.6.2, APIs not returning JSON cause errors
    // @todo See https://msjira.morningstar.com/browse/IX-1108
    try {
        await window.PFM_ORCHESTRATION.trigger('aggregation', null, callback);
    } catch (e) {
        callback({ response: false });
    }

    triggerBaaUpdate();
}

async function unlinkAccount(action, dataSources) {
    const { _sharedConfig, _route, ...apiSettings } = dataSources;
    const { detail } = action;
    const account = detail[0];
    const {
        id: accountId,
        resetStatus,
        isInvestment
    } = account;

    const { unlinkAccountSource } = apiSettings;

    await unlinkBaaAccount(accountId, unlinkAccountSource);

    if (resetStatus) {
        resetAggregationStatus();
    }

    triggerBaaAccountUnlink(account);

    if (isInvestment) {
        await triggerNetWorthUpdate();
    }
}

let credentialsChanged = false;
async function pfmDataChanged() {
    credentialsChanged = true;
}

async function pfmModalExit() {
    if (credentialsChanged) {
        triggerBaaUpdate();
        credentialsChanged = false;
    }
}

export default {
    'pfm-layout-render': noop,
    'pfm-layout-destroy': noop,
    // We don't want to emit BAA's event names
    byAllAccounts: {
        badAuthentication: noop,
        badConfiguration: noop,
        dataChanged: noop,
        dataChangedAsync: triggerAggregation,
        internalError: noop,
        userExit: noop
    },
    pfmAccountList: {
        'baa-bad-authentication': noop,
        'baa-bad-configuration': noop,
        'baa-data-changed': pfmDataChanged,
        'baa-data-changed-async': addedCredential,
        'baa-delete-credential': deleteCredential,
        'baa-internal-error': noop,
        'baa-unlink-account': unlinkAccount,
        'baa-user-exit': pfmModalExit,
        'pfm-account-aggregation-failed': noop,
        'pfm-account-aggregation-succeeded': noop,
        'pfm-component-destroyed': noop,
        'pfm-component-rendered': prepareSources,
        'pfm-credential-errors': noop,
        'pfm-delete-accepted': deleteNetWorthAccount,
        'pfm-show-support-modal': showSupportModal,
        'pfm-unlink-accepted': unlinkAccount
    }
};
