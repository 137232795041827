import { isValidObject, isValidString } from './valid';

/**
 * @function isValidElement
 * @description Verifies that an item is a valid HTML Element
 * @param {*} element The element to validate
 * @returns {boolean} Is the element a valid HTML Element?
 */
export function isValidElement(element) {
    return isValidObject(element)
        && (element instanceof HTMLElement || element.nodeType === 1);
}

/**
 * @function findTargetElement
 * @description Generates an element reference from `mwcId` and event `target`
 * @param {string} mwcId The MWC ID for the element
 * @param {*} target The target property of an event
 * @returns {object} The located element
 */
export function findTargetElement(mwcId, target) {
    return isValidString(mwcId)
        ? document.querySelector(`[mwc-id="${mwcId}"]`) || target
        : target;
}

/**
 * @function setElementLoading
 * @description Sets the `loading` flag on an element
 * @param {boolean} loading The state for the loading flag
 * @param {string} mwcId The MWC ID for the element
 * @param {*} target The target property of an event
 * @returns {boolean} Was the element updated?
 */
export function setElementLoading({
    loading,
    mwcId,
    target
}) {
    const element = findTargetElement(mwcId, target);

    if (!isValidElement(element)) {
        return false;
    }

    element.loading = loading;
    return true;
}

/**
 * @function screenReaderStatus
 * @description Adds new status message to DOM for screen reader to read once and removes the message after 1 second.
 * @param {string} message The status message
 */
export function screenReaderStatus(message) {
    const p = document.createElement('p');
    p.textContent = message;
    const statusMessageDiv = document.querySelector('#pfmScreenReaderStatus');
    if (statusMessageDiv) {
        statusMessageDiv.append(p);
        setTimeout(() => {
            statusMessageDiv.innerHTML = '';
        }, 1000);
    }
}

export default null;
